'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {observer} = require('mobx-react')
const PropsResolver = require('../components/PropsResolver')
const StyleNode = require('../components/StyleNode')

const ChildrenComponent = ({id, componentsModelAspect}) => {
    const childIds = componentsModelAspect.getStructureProperty(id, componentsModelAspect.pointers.components.PROPERTY_TYPES.COMPONENTS)
    return _.map(childIds, childId => <RootComponent id={childId} key={childId}/>)
}

ChildrenComponent.propTypes = {
    id: PropTypes.string.isRequired,
    componentsModelAspect: PropTypes.shape({
        getStructureProperty: PropTypes.func.isRequired,
        pointers: PropTypes.object.isRequired
    }).isRequired
}

const ChildrenObserver = observer(ChildrenComponent)

const RootComponent = ({id}, {componentsModelAspect}) => {
    const {pointers} = componentsModelAspect
    const style = <StyleNode id={id} key="style"/>

    const compType = componentsModelAspect.getStructureProperty(id, pointers.components.PROPERTY_TYPES.TYPE)
    if (compType === 'Component') {
        return (
            <React.Fragment>
                {style}
                <PropsResolver id={id} key="comp"/>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {style}
            <PropsResolver id={id} key="comp">
                <ChildrenObserver key="compChildrenResolver" id={id} componentsModelAspect={componentsModelAspect}/>
            </PropsResolver>
        </React.Fragment>
    )
}

RootComponent.propTypes = {
    id: PropTypes.string.isRequired
}

RootComponent.contextTypes = {
    componentsModelAspect: PropTypes.shape({
        getStructureProperty: PropTypes.func.isRequired,
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired
}


module.exports = RootComponent
