'use strict'

const _ = require('lodash')
const pointers = require('./pointers')

const createDataUpdaters = model => {
    const updateDataItem = dataMap => (id, value) => model.updateDataItem(dataMap, id, value)

    return _.mapValues(pointers.data.DATA_MAPS, map => updateDataItem(map))
}

const createStructureUpdaters = model => {
    const updateComponentProperty = property => (id, value) => model.updateComponentProperty(id, property, value)

    return _.defaults({
        COMPONENT: (id, value) => model.updateComponent(id, value)
    }, _.mapValues(pointers.components.PROPERTY_TYPES, property => updateComponentProperty(property)))
}

module.exports = {
    create: model => {
        const structureUpdaters = createStructureUpdaters(model)
        const dataUpdaters = createDataUpdaters(model)
        const modelUpdaters = _.assign({}, dataUpdaters, structureUpdaters)
        return {
            get: pointer => _.get(model, pointers.getPath(pointer)),
            getKeys: pointer => {
                const value = _.get(model, pointers.getPath(pointer))
    
                return _.keys(value)
            },
            isExist: pointer => _.has(model, pointers.getPath(pointer)),
            merge: (pointer, value) => {
                const currentValue = _.get(model, pointers.getPath(pointer))
                const newValue = _.assign({}, currentValue, value)
    
                modelUpdaters[pointer.type](pointer.id, newValue)
            },
            push: (pointer, valueToPush, index) => {
                if (pointer.type !== 'COMPONENTS') {
                    throw new Error(`push is allowed only to update children. Invalid pointer: ${pointer}`)  
                }

                const value = _.get(model, pointers.getPath(pointer))
                index = index || value.length    
                model.spliceComponents(pointer.id, index, 0, valueToPush)
            },
            remove: pointer => {
                modelUpdaters[pointer.type](pointer.id, undefined)
            },
            set: (pointer, value) => modelUpdaters[pointer.type](pointer.id, value)
        }
    }
}
