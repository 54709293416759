'use strict'

module.exports = {
    create: ({displayedDAL, pointers, getDataByQuery, getStructure, getStructureProperty}) => ({
        Fonts: {
            fontsMap: () => displayedDAL.get(pointers.data.getThemeDataPointer()).font
        },
        Component: {
            structure: (state, {id}) => getStructure(id),
            id: (state, {id}) => id,
            styleId: (state, {id}) => getStructureProperty(id, 'styleId'),
            style: (state, {id}) => {
                const {width, height, x: left, y: top} = getStructureProperty(id, 'layout')
                return {
                    width,
                    height,
                    top,
                    left,
                    position: 'absolute'
                }
            },
            skin: (state, {id}) => {
                const styleId = getStructureProperty(id, 'styleId')
                if (styleId) {
                    const styleData = displayedDAL.get(pointers.data.getStylePointer(styleId)) || {}
                    return styleData.skin || getStructureProperty(id, 'skin')
                }
                return getStructureProperty(id, 'skin')
            },
            compData(state, {compData, id}) {
                if (compData) {
                    return compData
                }

                const dataQuery = getStructureProperty(id, 'dataQuery')
                if (!dataQuery) {
                    return null
                }

                return getDataByQuery(dataQuery)
            },
            compProp(state, {compProp, id}) {
                if (compProp) {
                    return compProp
                }

                const propertyQuery = getStructureProperty(id, 'propertyQuery')
                if (!propertyQuery) {
                    return {}
                }

                return getDataByQuery(propertyQuery, 'PROPERTIES')
            },
            compActions: () => ({})
        }
    })
}
