'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {observer} = require('mobx-react')

const getSantaTypesFromReactClass = _.memoize(reactClass =>
    _.pickBy(reactClass.propTypes, 'id')
)

function createState(context) {
    return {
        fetchSantaType: context.fetchSantaType
    }
}

function createCompProps(context, CompClass, ownProps) {
    const fetcherState = createState(context)

    return _(CompClass)
        .thru(getSantaTypesFromReactClass)
        .mapValues(santaTypeDefinition =>
            context.fetchSantaType(
                santaTypeDefinition,
                fetcherState,
                ownProps
            )
        )
        .defaults(ownProps)
        .value()
}

class PropsResolver extends React.Component {
    constructor(props, context) {
        super(props, context)
        context.onRenderStart(props.id)
        this.registerComponent = ref => {
            this.context.registerComponent(this.props.id, ref)
        }
    }

    componentDidCatch(e) {
        this.context.onRenderError(this.props.id, e)
    }

    componentDidMount() {
        if (!this.isReactClass) {
            this.registerComponent(this)
        }
        this.context.onRenderEnd(this.props.id)
    }

    componentWillUpdate() {
        this.context.onRenderStart(this.props.id)
    }

    componentDidUpdate() {
        this.context.onRenderEnd(this.props.id)
    }

    componentWillUnmount() {
        if (!this.isReactClass) {
            this.registerComponent(null)
        }
    }

    render() {
        const componentType = this.context.componentsModelAspect.getStructureProperty(this.props.id, 'componentType')
        const CompClass = this.context.getCompClass(componentType)

        if (!CompClass) {
            return <div id={this.props.id} data-dead-comp />
        }

        const nextProps = createCompProps(
            this.context,
            CompClass,
            this.props
        )

        this.isReactClass = this.isReactClass || _.hasIn(CompClass, 'prototype.isReactComponent')
        return this.isReactClass ?
            <CompClass ref={this.registerComponent} {...nextProps} /> :
            <CompClass {...nextProps} />
    }
}

PropsResolver.displayName = 'PropsResolver'

PropsResolver.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node
}

PropsResolver.contextTypes = {
    getCompClass: PropTypes.func.isRequired,
    fetchSantaType: PropTypes.func.isRequired,
    getAspect: PropTypes.func.isRequired,
    onRenderStart: PropTypes.func.isRequired,
    onRenderEnd: PropTypes.func.isRequired,
    onRenderError: PropTypes.func.isRequired,
    registerComponent: PropTypes.func.isRequired,
    componentsModelAspect: PropTypes.shape({
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired
}

module.exports = observer(PropsResolver)
