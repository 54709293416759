'use strict'

const _ = require('lodash')
const mobx = require('mobx')
const displayedDal = require('./displayedDal')
const pointers = require('./pointers')
const componentPropsFetchers = require('./componentPropsFetchers')
const modelBuilder = require('./carmi/modelBuilder')
const StructureNode = require('../../utils/StructureNode')

const privatesMap = new WeakMap()

const createFunctionLibrary = (schemas, mobxModel) => ({
    isRef: (value, key, type) => schemas[type] && schemas[type][key] === 'ref',
    isRefList: (value, key, type) => schemas[type] && schemas[type][key] === 'refList',
    removeHash: value => value.replace('#', ''),
    updateMobxData: (dataItem, id, map) => {
        mobxModel.data[map].set(id, _.clone(dataItem))
        return dataItem
    },
    updateMobxStructure: structure => {
        const current = mobxModel.structure.get(structure.id)
        if (current) {
            _.assign(current, structure)
        } else {
            mobxModel.structure.set(structure.id, new StructureNode(structure))
        }
        return structure
    }
})

class ComponentsModelAspect {
    constructor(componentsModel, schemas = {}) {
        const mobxModel = {
            structure: mobx.observable.shallowMap(),
            data: _(pointers.data.DATA_MAPS).keyBy().mapValues(() => mobx.observable.shallowMap()).value()
        }

        const runtime = {
            data: _(pointers.data.DATA_MAPS).keyBy().mapValues(() => ({})).value()
        }
        const activeModes = {}
        const model = modelBuilder(_.defaults({activeModes, runtime}, componentsModel), createFunctionLibrary(schemas, mobxModel))

        const getStructure = id => mobxModel.structure.get(id)

        this.pointers = pointers
        this.displayedDAL = displayedDal.create(model)
        this.getDataByQuery = (dataQuery, type = 'DATA') => mobxModel.data[pointers.data.DATA_MAPS[type]].get(dataQuery.replace('#', ''))
        this.getStructureProperty = (id, property) => {
            const path = pointers.getPath(pointers.components.getProperty(pointers.components.getComponent(id), property))
            return _.get(getStructure(id), _.last(path))
        }

        privatesMap.set(this, {
            model,
            mobxModel,
            propsFetcher: componentPropsFetchers.create(_.defaults({getStructure}, this))
        })
    }

    getFetcher(santaTypeDefinition) {
        const {propsFetcher} = privatesMap.get(this)
        return _.get(propsFetcher, santaTypeDefinition.id)
    }

    updateActiveModes(modeId, value) {
        const {model} = privatesMap.get(this)
        model.updateActiveModes(modeId, value)
    }

    setRuntimeOverride(map, compId, partialOverrides) {
        const {model} = privatesMap.get(this)
        model.updateComponentOverrides(map, compId, partialOverrides)
    }
}

module.exports = ComponentsModelAspect
