'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {observer} = require('mobx-react')

const addRootId = (styleRoot, content) => {
    const cssLines = content.trim().split('\n')
    return _(cssLines).map(line => {
        const lineParts = line.split('{')
        const selectorParts = lineParts[0].split(',')
        selectorParts[0] = selectorParts[0].trimLeft()
        lineParts[0] = _.map(selectorParts, part => `#${styleRoot} ${part}`)
        return lineParts.join('{')
    }).join('\n')
}


// Replace this method with santaTypes fetching for the component
function getCssProps({displayedDAL, pointers}) {
    const themeData = displayedDAL.get(pointers.data.getThemeDataPointer())
    return {themeData, reportMissingSkin: _.noop}
}

const StyleNode = ({id}, {componentsModelAspect, getCompClass, rootId}) => {
    const {displayedDAL, pointers} = componentsModelAspect
    const componentPointer = pointers.components.getComponent(id)
    const styleId = displayedDAL.get(pointers.components.getProperty(componentPointer, 'styleId'))
    let styleData = styleId && displayedDAL.get(pointers.data.getStylePointer(styleId)) || {}
    const skinData = _.get(styleData, 'skin') || displayedDAL.get(pointers.components.getProperty(componentPointer, 'skin'))
    styleData = _.defaults({skin: skinData}, styleData)
    if (!skinData) {
        return null
    }

    const componentType = displayedDAL.get(pointers.components.getProperty(componentPointer, 'componentType'))
    const componentClass = getCompClass(componentType)

    if (!componentClass || !componentClass.getCompCss) {
        return null
    }

    const props = getCssProps(componentsModelAspect)
    const content = componentClass.getCompCss(styleId, styleData, props)
    return <style 
        id={styleId} 
        type="text/css" 
        data-styleid={styleId}
        dangerouslySetInnerHTML={{__html: rootId ? addRootId(rootId, content) : content || ''}}/>
}

StyleNode.displayName = 'ComponentStyleNode'

StyleNode.propTypes = {
    id: PropTypes.string.isRequired
}

StyleNode.contextTypes = {
    getCompClass: PropTypes.func.isRequired,
    componentsModelAspect: PropTypes.shape({
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired,
    rootId: PropTypes.string
}


module.exports = observer(StyleNode)
