'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const RootComponent = require('../components/RootComponent')
const ComponentsModelAspect = require('../aspects/ComponentsModelAspect/ComponentsModelAspect')

const privatesMap = new WeakMap()

function fetchSantaTypeImpl(santaTypeDefinition, state, props) {
    const {componentsModelAspect} = privatesMap.get(this)
    const santaTypeFetcher =
        this.props.getSantaFetcher(santaTypeDefinition) || componentsModelAspect.getFetcher(santaTypeDefinition)

    return santaTypeFetcher ? santaTypeFetcher(state, props) : null
}

class Renderer extends React.Component {
    constructor(props) {
        super(props)
        const componentsModelAspect =
            props.aspects.componentsModelAspect || new ComponentsModelAspect(props.componentsModel)

        privatesMap.set(this, {
            componentsModelAspect,
            aspects: _.defaults({componentsModelAspect}, props.aspects),
            fetchSantaType: fetchSantaTypeImpl.bind(this)
        })
    }

    render() {
        const COMPONENT_PROPS = _(Renderer.propTypes)
            .keys()
            .without('id')
            .value()
        const domProps = _.omit(this.props, COMPONENT_PROPS)
        return <div {...domProps}>
            {_.map(this.props.rootCompsIds, childId => <RootComponent id={childId} key={childId} />)}
        </div>
    }

    getChildContext() {
        const {componentsModelAspect, fetchSantaType, aspects} = privatesMap.get(this)
        return {
            rootId: this.props.id,
            componentsModelAspect,
            fetchSantaType,
            getAspect: name => aspects[name],
            getCompClass: this.props.getCompClass,
            onRenderStart: () => {},
            onRenderEnd: () => {},
            onRenderError: () => {},
            registerComponent: () => {}
        }
    }
}

Renderer.defaultProps = {
    getSantaFetcher: _.noop,
    aspects: {}
}

Renderer.displayName = 'Renderer'

Renderer.propTypes = {
    eventsManager: PropTypes.shape({
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
        emit: PropTypes.func.isRequired
    }),
    componentsModel: PropTypes.shape({
        structure: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired
    }),
    getCompClass: PropTypes.func.isRequired,
    getSantaFetcher: PropTypes.func.isRequired,
    aspects: PropTypes.object,
    rootCompsIds: PropTypes.arrayOf(PropTypes.string.isRequired),
    id: PropTypes.string
}

Renderer.childContextTypes = {
    getCompClass: PropTypes.func.isRequired,
    fetchSantaType: PropTypes.func.isRequired,
    getAspect: PropTypes.func.isRequired,
    onRenderStart: PropTypes.func.isRequired,
    onRenderEnd: PropTypes.func.isRequired,
    onRenderError: PropTypes.func.isRequired,
    registerComponent: PropTypes.func.isRequired,
    componentsModelAspect: PropTypes.shape({
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired,
    rootId: PropTypes.string
}

module.exports = Renderer
