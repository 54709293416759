'use strict'

const _ = require('lodash')

const POINTER_TO_MODEL_MAP = {
    COMPONENT_PROPERTY: {
        COMPONENTS: 'components',
        COMPONENT_TYPE: 'componentType',
        CONNECTION_QUERY: 'connectionQuery',
        DATA_QUERY: 'dataQuery',
        PROPERTY_QUERY: 'propertyQuery',
        DESIGN_QUERY: 'designQuery',
        BEHAVIOR_QUERY: 'behaviorQuery',
        ID: 'id',
        LAYOUT: 'layout',
        MOBILE_HINTS_QUERY: 'mobileHintsQuery',
        MODES: 'modes',
        SKIN: 'skin',
        STYLE_ID: 'styleId',
        PARENT: 'parent',
        TYPE: 'type'
    },
    DATA: {
        BEHAVIORS: 'behaviors_data',
        CONNECTIONS: 'connections_data',
        DATA: 'document_data',
        DESIGN: 'design_data',
        MOBILE_HINTS: 'mobile_hints',
        PROPERTIES: 'component_properties',
        STYLE: 'theme_data'
    }
}

const COMPONENT_POINTER_TYPE = 'COMPONENT'
const THEME_DATA_POINTER_ID = 'THEME_DATA'

const DATA_POINTER_TYPES = _.mapValues(POINTER_TO_MODEL_MAP.DATA, (v, type) => type)
const COMPONENT_PROPERTY_POINTER_TYPES = _.invert(POINTER_TO_MODEL_MAP.COMPONENT_PROPERTY)

const createTypedPointer = type => id => ({id: id.replace('#', ''), type})

module.exports = {
    components: {
        getComponent: createTypedPointer(COMPONENT_POINTER_TYPE),
        getProperty: (pointer, property) => {
            const type = COMPONENT_PROPERTY_POINTER_TYPES[property]
            if (pointer.type !== COMPONENT_POINTER_TYPE || !type) {
                throw new Error('pointer must be a component pointer or invalid property name')
            }

            return {id: pointer.id, type}
        },
        PROPERTY_TYPES: POINTER_TO_MODEL_MAP.COMPONENT_PROPERTY
    },
    data: {
        getBehaviorsPointer: createTypedPointer(DATA_POINTER_TYPES.BEHAVIORS),
        getConnectionsPointer: createTypedPointer(DATA_POINTER_TYPES.CONNECTIONS),
        getDataPointer: createTypedPointer(DATA_POINTER_TYPES.DATA),
        getDesignPointer: createTypedPointer(DATA_POINTER_TYPES.DESIGN),
        getMobileHintsPointer: createTypedPointer(DATA_POINTER_TYPES.MOBILE_HINTS),
        getPropertiesPointer: createTypedPointer(DATA_POINTER_TYPES.PROPERTIES),
        getStylePointer: createTypedPointer(DATA_POINTER_TYPES.STYLE),
        getThemeDataPointer: () => createTypedPointer(DATA_POINTER_TYPES.STYLE)(THEME_DATA_POINTER_ID),
        DATA_MAPS: POINTER_TO_MODEL_MAP.DATA
    },
    getPath: ({id, type}) => {
        if (type === COMPONENT_POINTER_TYPE) {
            return ['structure', id]
        }

        const property = _.get(POINTER_TO_MODEL_MAP.COMPONENT_PROPERTY, type)
        if (property) {
            return ['structure', id, property]
        }

        const dataMap = _.get(POINTER_TO_MODEL_MAP.DATA, type)
        if (dataMap) {
            return ['data', dataMap, id]
        }

        throw new Error(`Invalid pointer type: ${type}`)
    }
}
