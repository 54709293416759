(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("mobx"), require("prop-types"), require("react"), require("mobx-react"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "mobx", "prop-types", "react", "mobx-react"], factory);
	else if(typeof exports === 'object')
		exports["santa-renderer"] = factory(require("lodash"), require("mobx"), require("prop-types"), require("react"), require("mobx-react"));
	else
		root["santa-renderer"] = factory(root["lodash"], root["mobx"], root["prop-types"], root["react"], root["mobx-react"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__10__) {
return 