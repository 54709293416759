'use strict';

function model($model, $funcLib) {
  const $res = { $model };
  const $trackingMap = new WeakMap();
  const $trackedMap = new WeakMap();
  const $invalidatedMap = new WeakMap();
  const $parentObjectMap = new WeakMap();
  const $parentKeyMap = new WeakMap();
  const $invalidatedRoots = new Set();
  const $wildcard = "*****";
  let $tainted = new WeakSet();
  $invalidatedMap.set($res, $invalidatedRoots);

  const $uniquePersistentObjects = new Map();
  const getUniquePersistenObject = id => {
    if (!$uniquePersistentObjects.has(id)) {
      $uniquePersistentObjects.set(id, {});
    }
    return $uniquePersistentObjects.get(id);
  };

  const invalidate = ($targetKeySet, $targetKey) => {
    if ($targetKeySet.has($targetKey)) {
      return;
    }
    $targetKeySet.add($targetKey);
    if ($parentObjectMap.has($targetKeySet)) {
      invalidate(
        $parentObjectMap.get($targetKeySet),
        $parentKeyMap.get($targetKeySet)
      );
    }
  };

  const track = ($targetKeySet, $targetKey, $sourceObj, $sourceKey) => {
    if (!$sourceObj || !$targetKeySet) {
      return;
    }
    if (!$trackingMap.has($sourceObj)) {
      $trackingMap.set($sourceObj, {});
    }
    const $track = $trackingMap.get($sourceObj);
    $track[$sourceKey] = $track.hasOwnProperty($sourceKey)
      ? $track[$sourceKey]
      : new Map();
    if (!$track[$sourceKey].has($targetKeySet)) {
      $track[$sourceKey].set($targetKeySet, new Set());
    }
    $track[$sourceKey].get($targetKeySet).add($targetKey);
    if (!$trackedMap.has($targetKeySet)) {
      $trackedMap.set($targetKeySet, {});
    }
    const $tracked = $trackedMap.get($targetKeySet);
    $tracked[$targetKey] = $tracked[$targetKey] || [];
    $tracked[$targetKey].push({ $sourceKey, $sourceObj });
  };

  const untrack = ($targetKeySet, $targetKey) => {
    const $tracked = $trackedMap.get($targetKeySet);
    if (!$tracked || !$tracked[$targetKey]) {
      return;
    }
    $tracked[$targetKey].forEach(({ $sourceObj, $sourceKey }) => {
      const $trackingSource = $trackingMap.get($sourceObj);
      $trackingSource[$sourceKey].get($targetKeySet).delete($targetKey);
    });
    delete $tracked[$targetKey];
  };

  function triggerInvalidations($sourceObj, $sourceKey) {
    $tainted.add($sourceObj);
    if (!$trackingMap.has($sourceObj)) {
      return;
    }
    const $track = $trackingMap.get($sourceObj);
    if ($track.hasOwnProperty($sourceKey)) {
      $track[$sourceKey].forEach(($targetInvalidatedKeys, $targetKeySet) => {
        $targetInvalidatedKeys.forEach($targetKey =>
          invalidate($targetKeySet, $targetKey)
        );
      });
    }
    if ($track.hasOwnProperty($wildcard)) {
      $track[$wildcard].forEach(($targetInvalidatedKeys, $targetKeySet) => {
        $targetInvalidatedKeys.forEach($targetKey =>
          invalidate($targetKeySet, $sourceKey)
        );
      });
    }
  }

  function initOutput($targetObj, $targetKey, src, func, createDefaultValue) {
    let $new = false;
    func.output = func.output || new WeakMap();
    if (!func.output.has($targetObj)) {
      func.output.set($targetObj, new WeakMap());
    }
    const $targetOutputs = func.output.get($targetObj);
    if (!$targetOutputs.has(src)) {
      $targetOutputs.set(src, {});
    }
    const $targetSources = $targetOutputs.get(src);
    if (!$targetSources.hasOwnProperty($targetKey)) {
      $targetSources[$targetKey] = createDefaultValue();
      const $parentInvalidatedKeys = $invalidatedMap.get($targetObj);
      const $invalidatedKeys = new Set();
      $parentObjectMap.set($invalidatedKeys, $parentInvalidatedKeys);
      $parentKeyMap.set($invalidatedKeys, $targetKey);
      $invalidatedMap.set($targetSources[$targetKey], $invalidatedKeys);
      track($invalidatedKeys, $wildcard, src, $wildcard);
      $new = true;
    }
    const $out = $targetSources[$targetKey];
    return { $out, $new };
  }

  const emptyObj = () => {
    return {};
  };
  const emptyArr = () => [];

  function forObject($targetObj, $targetKey, func, src, context) {
    const { $out, $new } = initOutput(
      $targetObj,
      $targetKey,
      src,
      func,
      emptyObj
    );
    const $invalidatedKeys = $invalidatedMap.get($out);
    (($new && Object.keys(src)) || $invalidatedKeys).forEach(key => {
      func($invalidatedKeys, src, key, $out, context);
    });
    $invalidatedKeys.clear();
    return $out;
  }

  function forArray($targetObj, $targetKey, func, src, context) {
    const { $out, $new } = initOutput(
      $targetObj,
      $targetKey,
      src,
      func,
      emptyArr
    );
    const $invalidatedKeys = $invalidatedMap.get($out);
    if ($new) {
      for (let key = 0; key < src.length; key++) {
        func($invalidatedKeys, src, key, $out, context);
      }
    } else {
      $invalidatedKeys.forEach(key => {
        func($invalidatedKeys, src, key, $out, context);
      });
    }
    $invalidatedKeys.clear();
    return $out;
  }

  function recursiveSteps(key, $localInvalidatedKeys, $localKey) {
    const {
      $dependencyMap,
      $currentStack,
      $invalidatedKeys,
      $out,
      func,
      src,
      context
    } = this;
    if ($currentStack.length > 0) {
      const prevKey = $currentStack[$currentStack.length - 1];
      if (!$dependencyMap.has(key)) {
        $dependencyMap.set(key, []);
      }
      $dependencyMap.get(key).push({ $localInvalidatedKeys, $localKey });
    }
    if ($invalidatedKeys.has(key)) {
      $currentStack.push(key);
      $invalidatedKeys.delete(key);
      func($invalidatedKeys, src, key, $out, context, this);
      $currentStack.pop();
    }
    return $out[key];
  }

  function cascadeRecursiveInvalidations($loop) {
    const { $dependencyMap, $invalidatedKeys } = $loop;
    $invalidatedKeys.forEach(key => {
      if ($dependencyMap.has(key)) {
        $dependencyMap
          .get(key)
          .forEach(({ $localInvalidatedKeys, $localKey }) => {
            invalidate($localInvalidatedKeys, $localKey);
          });
        $dependencyMap.delete(key);
      }
    });
  }

  const $recursiveMapCache = new WeakMap();

  function recursiveMapObject($targetObj, $targetKey, func, src, context) {
    const { $out, $new } = initOutput(
      $targetObj,
      $targetKey,
      src,
      func,
      emptyObj
    );
    const $invalidatedKeys = $invalidatedMap.get($out);
    if ($new) {
      $recursiveMapCache.set($out, {
        $dependencyMap: new Map(),
        $currentStack: [],
        $invalidatedKeys,
        $out,
        func,
        src,
        context,
        recursiveSteps
      });
    }
    const $loop = $recursiveMapCache.get($out);
    $loop.context = context;
    if ($new) {
      Object.keys(src).forEach(key => $invalidatedKeys.add(key));
      Object.keys(src).forEach(key =>
        $loop.recursiveSteps(key, $invalidatedKeys, key)
      );
    } else {
      cascadeRecursiveInvalidations($loop);
      $invalidatedKeys.forEach(key => {
        $loop.recursiveSteps(key, $invalidatedKeys, key);
      });
    }
    $invalidatedKeys.clear();
    return $out;
  }

  const $keyByCache = new WeakMap();

  function keyByArray($targetObj, $targetKey, func, src, context) {
    const { $out, $new } = initOutput(
      $targetObj,
      $targetKey,
      src,
      func,
      emptyObj
    );
    const $invalidatedKeys = $invalidatedMap.get($out);
    if ($new) {
      $keyByCache.set($out, []);
    }
    const $idxToKey = $keyByCache.get($out);
    if ($new) {
      for (let key = 0; key < src.length; key++) {
        func($invalidatedKeys, $idxToKey, src, key, $out, context);
      }
    } else {
      const keysPendingDelete = new Set();
      $invalidatedKeys.forEach(key => keysPendingDelete.add($idxToKey[key]));
      $invalidatedKeys.forEach(key => {
        keysPendingDelete.delete(
          func($invalidatedKeys, $idxToKey, src, key, $out, context)
        );
      });
      keysPendingDelete.forEach(key => {
        triggerInvalidations($out, key);
        delete $out[key];
      });
    }
    $idxToKey.length = src.length;
    $invalidatedKeys.clear();
    return $out;
  }

  const $filterCache = new WeakMap();

  function filterArray($targetObj, $targetKey, func, src, context) {
    const { $out, $new } = initOutput(
      $targetObj,
      $targetKey,
      src,
      func,
      emptyArr
    );
    const $invalidatedKeys = $invalidatedMap.get($out);
    if ($new) {
      $filterCache.set($out, [0]);
    }
    const $idxToIdx = $filterCache.get($out);
    if ($new) {
      for (let key = 0; key < src.length; key++) {
        func($invalidatedKeys, $idxToIdx, src, key, $out, context);
      }
    } else {
      let firstIndex = Number.MAX_SAFE_INTEGER;
      $invalidatedKeys.forEach(key => (firstIndex = Math.min(firstIndex, key)));
      for (let key = firstIndex; key < src.length; key++) {
        func($invalidatedKeys, $idxToIdx, src, key, $out, context);
      }
      $idxToIdx.length = src.length + 1;
      for (
        let key = $idxToIdx[$idxToIdx.length - 1];
        key < $out.length;
        key++
      ) {
        triggerInvalidations($out, key);
      }
      $out.length = $idxToIdx[$idxToIdx.length - 1];
    }
    $invalidatedKeys.clear();
    return $out;
  }

  const $valuesOrKeysCache = new WeakMap();

  function valuesOrKeysForObject(
    $targetObj,
    $targetKey,
    identifier,
    src,
    getValues
  ) {
    const { $out, $new } = initOutput(
      $targetObj,
      $targetKey,
      src,
      identifier,
      emptyArr
    );
    if ($new) {
      const $keyToIdx = {};
      const $idxToKey = [];
      $valuesOrKeysCache.set($out, { $keyToIdx, $idxToKey });
      Object.keys(src).forEach((key, idx) => {
        $out[idx] = getValues ? src[key] : key;
        $idxToKey[idx] = key;
        $keyToIdx[key] = idx;
      });
    } else {
      const $invalidatedKeys = $invalidatedMap.get($out);
      const { $keyToIdx, $idxToKey } = $valuesOrKeysCache.get($out);
      const $deletedKeys = [];
      const $addedKeys = [];
      $invalidatedKeys.forEach(key => {
        if (src.hasOwnProperty(key) && !$keyToIdx.hasOwnProperty(key)) {
          $addedKeys.push(key);
        } else if (!src.hasOwnProperty(key) && $keyToIdx.hasOwnProperty(key)) {
          $deletedKeys.push(key);
        } else {
          if ($keyToIdx.hasOwnProperty(key)) {
            $out[$keyToIdx[key]] = src[key];
            triggerInvalidations($out, $keyToIdx[key]);
          }
        }
      });
      if ($addedKeys.length < $deletedKeys.length) {
        $deletedKeys.sort((a, b) => $keyToIdx[a] - $keyToIdx[b]);
      }
      const $finalOutLength =
        $out.length - $deletedKeys.length + $addedKeys.length;
      // keys both deleted and added fill created holes first
      for (let i = 0; i < $addedKeys.length && i < $deletedKeys.length; i++) {
        const $addedKey = $addedKeys[i];
        const $deletedKey = $deletedKeys[i];
        const $newIdx = $keyToIdx[$deletedKey];
        delete $keyToIdx[$deletedKey];
        $keyToIdx[$addedKey] = $newIdx;
        $idxToKey[$newIdx] = $addedKey;
        $out[$newIdx] = getValues ? src[$addedKey] : $addedKey;
        triggerInvalidations($out, $newIdx);
      }
      // more keys added - append to end
      for (let i = $deletedKeys.length; i < $addedKeys.length; i++) {
        const $addedKey = $addedKeys[i];
        const $newIdx = $out.length;
        $keyToIdx[$addedKey] = $newIdx;
        $idxToKey[$newIdx] = $addedKey;
        $out[$newIdx] = getValues ? src[$addedKey] : $addedKey;
        triggerInvalidations($out, $newIdx);
      }
      // more keys deleted - move non deleted items at the tail to the location of deleted
      const $deletedNotMoved = $deletedKeys.slice($addedKeys.length);
      const $deletedNotMovedSet = new Set(
        $deletedKeys.slice($addedKeys.length)
      );
      const $keysToMoveInside = new Set(
        $idxToKey
          .slice($finalOutLength)
          .filter(key => !$deletedNotMovedSet.has(key))
      );
      let $savedCount = 0;
      for (let $tailIdx = $finalOutLength; $tailIdx < $out.length; $tailIdx++) {
        const $currentKey = $idxToKey[$tailIdx];
        if ($keysToMoveInside.has($currentKey)) {
          // need to move this key to one of the pending delete
          const $switchedWithDeletedKey = $deletedNotMoved[$savedCount];
          const $newIdx = $keyToIdx[$switchedWithDeletedKey];
          $out[$newIdx] = getValues ? src[$currentKey] : $currentKey;
          $keyToIdx[$currentKey] = $newIdx;
          $idxToKey[$newIdx] = $currentKey;
          delete $keyToIdx[$switchedWithDeletedKey];
          triggerInvalidations($out, $newIdx);
          $savedCount++;
        } else {
          delete $keyToIdx[$currentKey];
        }
        triggerInvalidations($out, $tailIdx);
      }
      $out.length = $finalOutLength;
      $idxToKey.length = $out.length;
      $invalidatedKeys.clear();
    }
    return $out;
  }

  const $arrayCache = new WeakMap();
  function getEmptyArray($invalidatedKeys, key, token) {
    if (!$arrayCache.has($invalidatedKeys)) {
      $arrayCache.set($invalidatedKeys, {});
    }
    const $cacheByKey = $arrayCache.get($invalidatedKeys);
    $cacheByKey[key] = $cacheByKey[key] || new Map();
    if (!$cacheByKey[key].has(token)) {
      $cacheByKey[key].set(token, []);
    }
    return $cacheByKey[key].get(token);
  }

  const $objectCache = new WeakMap();
  function getEmptyObject($invalidatedKeys, key, token) {
    if (!$objectCache.has($invalidatedKeys)) {
      $objectCache.set($invalidatedKeys, {});
    }
    const $cacheByKey = $objectCache.get($invalidatedKeys);
    $cacheByKey[key] = $cacheByKey[key] || new Map();
    if (!$cacheByKey[key].has(token)) {
      $cacheByKey[key].set(token, {});
    }
    return $cacheByKey[key].get(token);
  }

  function array($invalidatedKeys, key, newVal, identifier, len, invalidates) {
    const res = getEmptyArray($invalidatedKeys, key, identifier);
    for (let i = 0; i < len; i++) {
      if (
        invalidates &&
        res.length === len &&
        (res[i] !== newVal[i] ||
          (typeof newVal[i] === "object" && $tainted.has(newVal[i])))
      ) {
        triggerInvalidations(res, i);
      }
      res[i] = newVal[i];
    }
    return res;
  }

  function object(
    $invalidatedKeys,
    key,
    newVal,
    identifier,
    keysList,
    invalidates
  ) {
    const res = getEmptyObject($invalidatedKeys, key, identifier);
    for (let i = 0; i < keysList.length; i++) {
      let name = keysList[i];
      if (
        invalidates &&
        res.hasOwnProperty(name) &&
        (res[name] !== newVal[name] ||
          (typeof newVal[name] === "object" && $tainted.has(newVal[name])))
      ) {
        triggerInvalidations(res, name);
      }
      res[name] = newVal[name];
    }
    return res;
  }

  function assignOrDefaults($targetObj, $targetKey, identifier, src, assign) {
    const { $out, $new } = initOutput(
      $targetObj,
      $targetKey,
      src,
      identifier,
      emptyObj
    );
    if (!assign) {
      src = [...src].reverse();
    }
    if ($new) {
      Object.assign($out, ...src);
    } else {
      const $invalidatedKeys = $invalidatedMap.get($out);
      const $keysPendingDelete = new Set(Object.keys($out));
      const res = Object.assign({}, ...src);
      Object.keys(res).forEach(key => {
        $keysPendingDelete.delete(key);
        if (
          $out[key] !== res[key] ||
          (typeof res[key] === "object" && $tainted.has(res[key])) ||
          (!$out.hasOwnProperty(key) && res[key] === undefined)
        ) {
          triggerInvalidations($out, key);
        }
        $out[key] = res[key];
      });
      $keysPendingDelete.forEach(key => {
        delete $out[key];
        triggerInvalidations($out, key);
      });
      $invalidatedKeys.clear();
    }
    return $out;
  }

  function behaviors_data$0($invalidatedKeys, src, key, acc, context) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $funcLib["updateMobxData"](val, key, "behaviors_data");
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }
  }

  $invalidatedRoots.add("behaviors_data");
  function $behaviors_dataBuild() {
    const acc = $res;
    const key = "behaviors_data";
    const prevValue = $res.behaviors_data;
    const $invalidatedKeys = $invalidatedRoots;
    $res.behaviors_data = forObject(
      acc,
      key,
      behaviors_data$0,
      $res["$mapValues$recursiveMapValuesbehaviors_dataisRef8id55"],
      null
    );
    const $changed =
      prevValue !== $res.behaviors_data ||
      (typeof $res.behaviors_data === "object" &&
        $tainted.has($res.behaviors_data));
    $invalidatedRoots.delete("behaviors_data");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$recursiveMapValuesbehaviors_dataisRef8id55","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$recursiveMapValuesbehaviors_dataisRef8id55"
    );
    // tracking ["*topLevel* : ","$mapValues$recursiveMapValuesbehaviors_dataisRef8id55","*wildcard* : "]

    return $res.behaviors_data;
  }

  function connections_data$4($invalidatedKeys, src, key, acc, context) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $funcLib["updateMobxData"](val, key, "connections_data");
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }
  }

  $invalidatedRoots.add("connections_data");
  function $connections_dataBuild() {
    const acc = $res;
    const key = "connections_data";
    const prevValue = $res.connections_data;
    const $invalidatedKeys = $invalidatedRoots;
    $res.connections_data = forObject(
      acc,
      key,
      connections_data$4,
      $res["$mapValues$recursiveMapValuesconnections_dataisRef9id56"],
      null
    );
    const $changed =
      prevValue !== $res.connections_data ||
      (typeof $res.connections_data === "object" &&
        $tainted.has($res.connections_data));
    $invalidatedRoots.delete("connections_data");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$recursiveMapValuesconnections_dataisRef9id56","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$recursiveMapValuesconnections_dataisRef9id56"
    );
    // tracking ["*topLevel* : ","$mapValues$recursiveMapValuesconnections_dataisRef9id56","*wildcard* : "]

    return $res.connections_data;
  }

  function document_data$8($invalidatedKeys, src, key, acc, context) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $funcLib["updateMobxData"](val, key, "document_data");
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }
  }

  $invalidatedRoots.add("document_data");
  function $document_dataBuild() {
    const acc = $res;
    const key = "document_data";
    const prevValue = $res.document_data;
    const $invalidatedKeys = $invalidatedRoots;
    $res.document_data = forObject(
      acc,
      key,
      document_data$8,
      $res["$mapValues$recursiveMapValuesdocument_dataisRef7id53"],
      null
    );
    const $changed =
      prevValue !== $res.document_data ||
      (typeof $res.document_data === "object" &&
        $tainted.has($res.document_data));
    $invalidatedRoots.delete("document_data");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$recursiveMapValuesdocument_dataisRef7id53","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$recursiveMapValuesdocument_dataisRef7id53"
    );
    // tracking ["*topLevel* : ","$mapValues$recursiveMapValuesdocument_dataisRef7id53","*wildcard* : "]

    return $res.document_data;
  }

  function design_data2($invalidatedKeys, src, key, acc, context) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $funcLib["updateMobxData"](val, key, "design_data");
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }
  }

  $invalidatedRoots.add("design_data");
  function $design_dataBuild() {
    const acc = $res;
    const key = "design_data";
    const prevValue = $res.design_data;
    const $invalidatedKeys = $invalidatedRoots;
    $res.design_data = forObject(
      acc,
      key,
      design_data2,
      $res["$mapValues$recursiveMapValuesdesign_dataisRef6id54"],
      null
    );
    const $changed =
      prevValue !== $res.design_data ||
      (typeof $res.design_data === "object" && $tainted.has($res.design_data));
    $invalidatedRoots.delete("design_data");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$recursiveMapValuesdesign_dataisRef6id54","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$recursiveMapValuesdesign_dataisRef6id54"
    );
    // tracking ["*topLevel* : ","$mapValues$recursiveMapValuesdesign_dataisRef6id54","*wildcard* : "]

    return $res.design_data;
  }

  function component_properties6($invalidatedKeys, src, key, acc, context) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $funcLib["updateMobxData"](val, key, "component_properties");
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }
  }

  $invalidatedRoots.add("component_properties");
  function $component_propertiesBuild() {
    const acc = $res;
    const key = "component_properties";
    const prevValue = $res.component_properties;
    const $invalidatedKeys = $invalidatedRoots;
    $res.component_properties = forObject(
      acc,
      key,
      component_properties6,
      $res["$mapValues$recursiveMapValuescomponent_propertiesisRef10id57"],
      null
    );
    const $changed =
      prevValue !== $res.component_properties ||
      (typeof $res.component_properties === "object" &&
        $tainted.has($res.component_properties));
    $invalidatedRoots.delete("component_properties");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$recursiveMapValuescomponent_propertiesisRef10id57","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$recursiveMapValuescomponent_propertiesisRef10id57"
    );
    // tracking ["*topLevel* : ","$mapValues$recursiveMapValuescomponent_propertiesisRef10id57","*wildcard* : "]

    return $res.component_properties;
  }

  function theme_data$20($invalidatedKeys, src, key, acc, context) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $funcLib["updateMobxData"](val, key, "theme_data");
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }
  }

  $invalidatedRoots.add("theme_data");
  function $theme_dataBuild() {
    const acc = $res;
    const key = "theme_data";
    const prevValue = $res.theme_data;
    const $invalidatedKeys = $invalidatedRoots;
    $res.theme_data = forObject(
      acc,
      key,
      theme_data$20,
      $res["$mapValues$recursiveMapValuestheme_dataisRef5id52"],
      null
    );
    const $changed =
      prevValue !== $res.theme_data ||
      (typeof $res.theme_data === "object" && $tainted.has($res.theme_data));
    $invalidatedRoots.delete("theme_data");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$recursiveMapValuestheme_dataisRef5id52","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$recursiveMapValuestheme_dataisRef5id52"
    );
    // tracking ["*topLevel* : ","$mapValues$recursiveMapValuestheme_dataisRef5id52","*wildcard* : "]

    return $res.theme_data;
  }

  function structure$24($invalidatedKeys, src, key, acc, context) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $funcLib["updateMobxStructure"](val);
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }
  }

  $invalidatedRoots.add("structure");
  function $structureBuild() {
    const acc = $res;
    const key = "structure";
    const prevValue = $res.structure;
    const $invalidatedKeys = $invalidatedRoots;
    $res.structure = forObject(
      acc,
      key,
      structure$24,
      $res["$mapValues$assignstructure17id58"],
      null
    );
    const $changed =
      prevValue !== $res.structure ||
      (typeof $res.structure === "object" && $tainted.has($res.structure));
    $invalidatedRoots.delete("structure");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$assignstructure17id58","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$mapValues$assignstructure17id58");
    // tracking ["*topLevel* : ","$mapValues$assignstructure17id58","*wildcard* : "]

    return $res.structure;
  }

  const object$30Token = getUniquePersistenObject(30);
  const object$30Args = [
    "propertyQuery",
    "isHiddenByModes",
    "layout",
    "designQuery",
    "styleId"
  ];

  function overridesOfCompInModeWithDefaults$28(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        {
          propertyQuery: val ? val["propertyQuery"] : false,
          isHiddenByModes: val ? val["isHiddenByModes"] : false,
          layout: val ? val["layout"] : false,
          designQuery: val ? val["designQuery"] : false,
          styleId: val ? val["styleId"] : false
        },
        object$30Token,
        object$30Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("overridesOfCompInModeWithDefaults");
  function $overridesOfCompInModeWithDefaultsBuild() {
    const acc = $res;
    const key = "overridesOfCompInModeWithDefaults";
    const prevValue = $res.overridesOfCompInModeWithDefaults;
    const $invalidatedKeys = $invalidatedRoots;
    $res.overridesOfCompInModeWithDefaults = forObject(
      acc,
      key,
      overridesOfCompInModeWithDefaults$28,
      $res["overridesOfCompInMode"],
      null
    );
    const $changed =
      prevValue !== $res.overridesOfCompInModeWithDefaults ||
      (typeof $res.overridesOfCompInModeWithDefaults === "object" &&
        $tainted.has($res.overridesOfCompInModeWithDefaults));
    $invalidatedRoots.delete("overridesOfCompInModeWithDefaults");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","overridesOfCompInMode","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "overridesOfCompInMode");
    // tracking ["*topLevel* : ","overridesOfCompInMode","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.overridesOfCompInModeWithDefaults;
  }

  function overridesOfCompInMode$42$51(
    $invalidatedKeys,
    $idxToIdx,
    src,
    key,
    acc,
    context
  ) {
    const val = src[key];
    const res = $res["activeModes"][val["modeIds"][0]];
    const prevItemIdx = $idxToIdx[key];
    const nextItemIdx = res ? prevItemIdx + 1 : prevItemIdx;
    let $changed = false;
    if (nextItemIdx !== prevItemIdx) {
      $changed =
        acc[prevItemIdx] !== val ||
        (typeof val === "object" && $tainted.has(val));
      acc[prevItemIdx] = val;
    }
    $idxToIdx[key + 1] = nextItemIdx;
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","activeModes",["*get* : $id:54, $funcId:\"overridesOfCompInMode$42$51\", $rootName:\"overridesOfCompInMode\", $depth:2, $funcType:\"filter\", $conditional:false",0,["*get* : $id:55, $funcId:\"overridesOfCompInMode$42$51\", $rootName:\"overridesOfCompInMode\", $depth:2, $funcType:\"filter\", $conditional:false","modeIds","*val* : "]]], false, undefined
    track($invalidatedKeys, key, $res["activeModes"], val["modeIds"][0]);
    // tracking ["*topLevel* : ","activeModes",["*get* : $id:54, $funcId:\"overridesOfCompInMode$42$51\", $rootName:\"overridesOfCompInMode\", $depth:2, $funcType:\"filter\", $conditional:false",0,["*get* : $id:55, $funcId:\"overridesOfCompInMode$42$51\", $rootName:\"overridesOfCompInMode\", $depth:2, $funcType:\"filter\", $conditional:false","modeIds","*val* : "]]]

    if ($changed) {
      triggerInvalidations(acc, prevItemIdx);
    }
  }

  const object$59Token = getUniquePersistenObject(59);
  const object$59Args = ["isHiddenByModes"];

  function overridesOfCompInMode$42($invalidatedKeys, src, key, acc, context) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        (val["modes"] &&
          val["modes"]["overrides"] &&
          (filterArray(
            acc,
            key,
            overridesOfCompInMode$42$51,
            val["modes"]["overrides"],
            null
          )[0] ||
            object(
              $invalidatedKeys,
              key,
              { isHiddenByModes: val["modes"]["isHiddenByModes"] },
              object$59Token,
              object$59Args,
              true
            ))) ||
        false;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("overridesOfCompInMode");
  function $overridesOfCompInModeBuild() {
    const acc = $res;
    const key = "overridesOfCompInMode";
    const prevValue = $res.overridesOfCompInMode;
    const $invalidatedKeys = $invalidatedRoots;
    $res.overridesOfCompInMode = forObject(
      acc,
      key,
      overridesOfCompInMode$42,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.overridesOfCompInMode ||
      (typeof $res.overridesOfCompInMode === "object" &&
        $tainted.has($res.overridesOfCompInMode));
    $invalidatedRoots.delete("overridesOfCompInMode");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.overridesOfCompInMode;
  }

  $invalidatedRoots.add("data");
  function $dataBuild() {
    const acc = $res;
    const key = "data";
    const prevValue = $res.data;
    const $invalidatedKeys = $invalidatedRoots;
    $res.data = $model["data"];
    const $changed =
      prevValue !== $res.data ||
      (typeof $res.data === "object" && $tainted.has($res.data));
    $invalidatedRoots.delete("data");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*root* : ","data"], false, undefined
    // path matched ["*root* : ","data","*arg0* : ","*arg1* : "]
    track($invalidatedKeys, key, $model, "data");
    // path matched ["*root* : ","data","document_data","*arg0* : ","*arg1* : "]
    track($invalidatedKeys, key, $model, "data");
    // tracking model directly
    // tracking ["*root* : ","data"]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.data;
  }

  $invalidatedRoots.add("runtime");
  function $runtimeBuild() {
    const acc = $res;
    const key = "runtime";
    const prevValue = $res.runtime;
    const $invalidatedKeys = $invalidatedRoots;
    $res.runtime = $model["runtime"];
    const $changed =
      prevValue !== $res.runtime ||
      (typeof $res.runtime === "object" && $tainted.has($res.runtime));
    $invalidatedRoots.delete("runtime");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*root* : ","runtime"], false, undefined
    // path matched ["*root* : ","runtime","data","*arg0* : ","*arg1* : "]
    track($invalidatedKeys, key, $model, "runtime");
    // tracking model directly
    // tracking ["*root* : ","runtime"]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.runtime;
  }

  const array$66Token = getUniquePersistenObject(66);
  const array$66Args = 2;

  $invalidatedRoots.add("activeModes");
  function $activeModesBuild() {
    const acc = $res;
    const key = "activeModes";
    const prevValue = $res.activeModes;
    const $invalidatedKeys = $invalidatedRoots;
    $res.activeModes = assignOrDefaults(
      acc,
      key,
      getUniquePersistenObject(65),
      array(
        $invalidatedKeys,
        key,
        [
          $res[
            "$assign$values$mapValues$mapValues$assignstructure17modes36type434751"
          ],
          $model["activeModes"]
        ],
        array$66Token,
        array$66Args,
        true
      ),
      true
    );
    const $changed =
      prevValue !== $res.activeModes ||
      (typeof $res.activeModes === "object" && $tainted.has($res.activeModes));
    $invalidatedRoots.delete("activeModes");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assign$values$mapValues$mapValues$assignstructure17modes36type434751"], false, undefined
    track(
      $invalidatedKeys,
      key,
      $res,
      "$assign$values$mapValues$mapValues$assignstructure17modes36type434751"
    );
    // tracking ["*topLevel* : ","$assign$values$mapValues$mapValues$assignstructure17modes36type434751"]
    //invalidatedPath: ["*root* : ","activeModes"], false, undefined
    // path matched ["*root* : ","activeModes","*arg0* : "]
    track($invalidatedKeys, key, $model, "activeModes");
    // tracking model directly
    // tracking ["*root* : ","activeModes"]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.activeModes;
  }

  function $mapValues$assignstructure17id58$6927(
    $invalidatedKeys,
    $idxToIdx,
    src,
    key,
    acc,
    context
  ) {
    const val = src[key];
    const res = $res["overridesOfCompInModeWithDefaults"][val]
      ? !$res["overridesOfCompInModeWithDefaults"][val]["isHiddenByModes"]
      : true;
    const prevItemIdx = $idxToIdx[key];
    const nextItemIdx = res ? prevItemIdx + 1 : prevItemIdx;
    let $changed = false;
    if (nextItemIdx !== prevItemIdx) {
      $changed =
        acc[prevItemIdx] !== val ||
        (typeof val === "object" && $tainted.has(val));
      acc[prevItemIdx] = val;
    }
    $idxToIdx[key + 1] = nextItemIdx;
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*val* : "], false, val
    track(
      $invalidatedKeys,
      key,
      $res["overridesOfCompInModeWithDefaults"],
      val
    );
    // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*val* : "]
    //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*val* : ","isHiddenByModes"], false, undefined
    track(
      $invalidatedKeys,
      key,
      $res["overridesOfCompInModeWithDefaults"][val],
      "isHiddenByModes"
    );
    // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*val* : ","isHiddenByModes"]

    if ($changed) {
      triggerInvalidations(acc, prevItemIdx);
    }
  }

  const object$71Token = getUniquePersistenObject(71);
  const object$71Args = [
    "id",
    "type",
    "parent",
    "componentType",
    "skin",
    "dataQuery",
    "modes",
    "connectionQuery",
    "propertyQuery",
    "layout",
    "designQuery",
    "behaviorQuery",
    "styleId",
    "components"
  ];

  function $mapValues$assignstructure17id58$69(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;
    let $cond_89 = false;
    let $cond_97 = false;
    let $cond_105 = false;
    let $cond_113 = false;
    let $cond_121 = false;
    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        {
          id: val["id"],
          type: val["type"],
          parent:
            !$res["overridesOfCompInModeWithDefaults"][key][
              "isHiddenByModes"
            ] && val["parent"],
          componentType: val["componentType"],
          skin: val["skin"],
          dataQuery: val["dataQuery"],
          modes: val["modes"],
          connectionQuery: val["connectionQuery"],
          propertyQuery:
            ($res["overridesOfCompInModeWithDefaults"][key] &&
              $res["overridesOfCompInModeWithDefaults"][key][
                ($cond_89 = "propertyQuery")
              ]) ||
            val["propertyQuery"],
          layout:
            ($res["overridesOfCompInModeWithDefaults"][key] &&
              $res["overridesOfCompInModeWithDefaults"][key][
                ($cond_97 = "layout")
              ]) ||
            val["layout"],
          designQuery:
            ($res["overridesOfCompInModeWithDefaults"][key] &&
              $res["overridesOfCompInModeWithDefaults"][key][
                ($cond_105 = "designQuery")
              ]) ||
            val["designQuery"],
          behaviorQuery:
            ($res["overridesOfCompInModeWithDefaults"][key] &&
              $res["overridesOfCompInModeWithDefaults"][key][
                ($cond_113 = "behaviorQuery")
              ]) ||
            val["behaviorQuery"],
          styleId:
            ($res["overridesOfCompInModeWithDefaults"][key] &&
              $res["overridesOfCompInModeWithDefaults"][key][
                ($cond_121 = "styleId")
              ]) ||
            val["styleId"],
          components:
            val["components"] &&
            filterArray(
              acc,
              key,
              $mapValues$assignstructure17id58$6927,
              val["components"],
              null
            )
        },
        object$71Token,
        object$71Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","isHiddenByModes"], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["overridesOfCompInModeWithDefaults"][key],
        "isHiddenByModes"
      );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","isHiddenByModes"]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res["overridesOfCompInModeWithDefaults"],
        key
      );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","propertyQuery"], 89, undefined
      $cond_89 &&
        track(
          $invalidatedKeys,
          key,
          $res["overridesOfCompInModeWithDefaults"][key],
          "propertyQuery"
        );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","propertyQuery"]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res["overridesOfCompInModeWithDefaults"],
        key
      );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","layout"], 97, undefined
      $cond_97 &&
        track(
          $invalidatedKeys,
          key,
          $res["overridesOfCompInModeWithDefaults"][key],
          "layout"
        );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","layout"]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res["overridesOfCompInModeWithDefaults"],
        key
      );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","designQuery"], 105, undefined
      $cond_105 &&
        track(
          $invalidatedKeys,
          key,
          $res["overridesOfCompInModeWithDefaults"][key],
          "designQuery"
        );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","designQuery"]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res["overridesOfCompInModeWithDefaults"],
        key
      );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","behaviorQuery"], 113, undefined
      $cond_113 &&
        track(
          $invalidatedKeys,
          key,
          $res["overridesOfCompInModeWithDefaults"][key],
          "behaviorQuery"
        );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","behaviorQuery"]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res["overridesOfCompInModeWithDefaults"],
        key
      );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : "]
      //invalidatedPath: ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","styleId"], 121, undefined
      $cond_121 &&
        track(
          $invalidatedKeys,
          key,
          $res["overridesOfCompInModeWithDefaults"][key],
          "styleId"
        );
      // tracking ["*topLevel* : ","overridesOfCompInModeWithDefaults","*key* : ","styleId"]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$assignstructure17id58");
  function $mapValues$assignstructure17id58Build() {
    const acc = $res;
    const key = "$mapValues$assignstructure17id58";
    const prevValue = $res.$mapValues$assignstructure17id58;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$assignstructure17id58 = forObject(
      acc,
      key,
      $mapValues$assignstructure17id58$69,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$assignstructure17id58 ||
      (typeof $res.$mapValues$assignstructure17id58 === "object" &&
        $tainted.has($res.$mapValues$assignstructure17id58));
    $invalidatedRoots.delete("$mapValues$assignstructure17id58");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$assignstructure17id58;
  }

  const array44Token = getUniquePersistenObject(144);
  const array44Args = 2;

  function $mapValues$recursiveMapValuescomponent_propertiesisRef10id5738(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res[
        "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50"
      ][key]
        ? assignOrDefaults(
            acc,
            key,
            getUniquePersistenObject(143),
            array(
              $invalidatedKeys,
              key,
              [
                val,
                $res[
                  "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50"
                ][key]
              ],
              array44Token,
              array44Args,
              true
            ),
            true
          )
        : val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50","*key* : "]
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50","*key* : "]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$recursiveMapValuescomponent_propertiesisRef10id57"
  );
  function $mapValues$recursiveMapValuescomponent_propertiesisRef10id57Build() {
    const acc = $res;
    const key = "$mapValues$recursiveMapValuescomponent_propertiesisRef10id57";
    const prevValue =
      $res.$mapValues$recursiveMapValuescomponent_propertiesisRef10id57;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$recursiveMapValuescomponent_propertiesisRef10id57 = forObject(
      acc,
      key,
      $mapValues$recursiveMapValuescomponent_propertiesisRef10id5738,
      $res["$recursiveMapValuescomponent_propertiesisRef10"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$recursiveMapValuescomponent_propertiesisRef10id57 ||
      (typeof $res.$mapValues$recursiveMapValuescomponent_propertiesisRef10id57 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$recursiveMapValuescomponent_propertiesisRef10id57
        ));
    $invalidatedRoots.delete(
      "$mapValues$recursiveMapValuescomponent_propertiesisRef10id57"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$recursiveMapValuescomponent_propertiesisRef10","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$recursiveMapValuescomponent_propertiesisRef10"
    );
    // tracking ["*topLevel* : ","$recursiveMapValuescomponent_propertiesisRef10","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$recursiveMapValuescomponent_propertiesisRef10id57;
  }

  const array54Token = getUniquePersistenObject(154);
  const array54Args = 2;

  function $mapValues$recursiveMapValuesconnections_dataisRef9id5648(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res[
        "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49"
      ][key]
        ? assignOrDefaults(
            acc,
            key,
            getUniquePersistenObject(153),
            array(
              $invalidatedKeys,
              key,
              [
                val,
                $res[
                  "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49"
                ][key]
              ],
              array54Token,
              array54Args,
              true
            ),
            true
          )
        : val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49","*key* : "]
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49","*key* : "]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$recursiveMapValuesconnections_dataisRef9id56"
  );
  function $mapValues$recursiveMapValuesconnections_dataisRef9id56Build() {
    const acc = $res;
    const key = "$mapValues$recursiveMapValuesconnections_dataisRef9id56";
    const prevValue =
      $res.$mapValues$recursiveMapValuesconnections_dataisRef9id56;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$recursiveMapValuesconnections_dataisRef9id56 = forObject(
      acc,
      key,
      $mapValues$recursiveMapValuesconnections_dataisRef9id5648,
      $res["$recursiveMapValuesconnections_dataisRef9"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$recursiveMapValuesconnections_dataisRef9id56 ||
      (typeof $res.$mapValues$recursiveMapValuesconnections_dataisRef9id56 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$recursiveMapValuesconnections_dataisRef9id56
        ));
    $invalidatedRoots.delete(
      "$mapValues$recursiveMapValuesconnections_dataisRef9id56"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$recursiveMapValuesconnections_dataisRef9","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$recursiveMapValuesconnections_dataisRef9"
    );
    // tracking ["*topLevel* : ","$recursiveMapValuesconnections_dataisRef9","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$recursiveMapValuesconnections_dataisRef9id56;
  }

  const array64Token = getUniquePersistenObject(164);
  const array64Args = 2;

  function $mapValues$recursiveMapValuesbehaviors_dataisRef8id5558(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res[
        "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48"
      ][key]
        ? assignOrDefaults(
            acc,
            key,
            getUniquePersistenObject(163),
            array(
              $invalidatedKeys,
              key,
              [
                val,
                $res[
                  "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48"
                ][key]
              ],
              array64Token,
              array64Args,
              true
            ),
            true
          )
        : val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48","*key* : "]
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48","*key* : "]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$recursiveMapValuesbehaviors_dataisRef8id55"
  );
  function $mapValues$recursiveMapValuesbehaviors_dataisRef8id55Build() {
    const acc = $res;
    const key = "$mapValues$recursiveMapValuesbehaviors_dataisRef8id55";
    const prevValue =
      $res.$mapValues$recursiveMapValuesbehaviors_dataisRef8id55;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$recursiveMapValuesbehaviors_dataisRef8id55 = forObject(
      acc,
      key,
      $mapValues$recursiveMapValuesbehaviors_dataisRef8id5558,
      $res["$recursiveMapValuesbehaviors_dataisRef8"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$recursiveMapValuesbehaviors_dataisRef8id55 ||
      (typeof $res.$mapValues$recursiveMapValuesbehaviors_dataisRef8id55 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$recursiveMapValuesbehaviors_dataisRef8id55
        ));
    $invalidatedRoots.delete(
      "$mapValues$recursiveMapValuesbehaviors_dataisRef8id55"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$recursiveMapValuesbehaviors_dataisRef8","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$recursiveMapValuesbehaviors_dataisRef8"
    );
    // tracking ["*topLevel* : ","$recursiveMapValuesbehaviors_dataisRef8","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$recursiveMapValuesbehaviors_dataisRef8id55;
  }

  const array74Token = getUniquePersistenObject(174);
  const array74Args = 2;

  function $mapValues$recursiveMapValuesdesign_dataisRef6id5468(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res[
        "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46"
      ][key]
        ? assignOrDefaults(
            acc,
            key,
            getUniquePersistenObject(173),
            array(
              $invalidatedKeys,
              key,
              [
                val,
                $res[
                  "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46"
                ][key]
              ],
              array74Token,
              array74Args,
              true
            ),
            true
          )
        : val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46","*key* : "]
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46","*key* : "]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$recursiveMapValuesdesign_dataisRef6id54");
  function $mapValues$recursiveMapValuesdesign_dataisRef6id54Build() {
    const acc = $res;
    const key = "$mapValues$recursiveMapValuesdesign_dataisRef6id54";
    const prevValue = $res.$mapValues$recursiveMapValuesdesign_dataisRef6id54;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$recursiveMapValuesdesign_dataisRef6id54 = forObject(
      acc,
      key,
      $mapValues$recursiveMapValuesdesign_dataisRef6id5468,
      $res["$recursiveMapValuesdesign_dataisRef6"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$recursiveMapValuesdesign_dataisRef6id54 ||
      (typeof $res.$mapValues$recursiveMapValuesdesign_dataisRef6id54 ===
        "object" &&
        $tainted.has($res.$mapValues$recursiveMapValuesdesign_dataisRef6id54));
    $invalidatedRoots.delete(
      "$mapValues$recursiveMapValuesdesign_dataisRef6id54"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$recursiveMapValuesdesign_dataisRef6","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$recursiveMapValuesdesign_dataisRef6");
    // tracking ["*topLevel* : ","$recursiveMapValuesdesign_dataisRef6","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$recursiveMapValuesdesign_dataisRef6id54;
  }

  const array84Token = getUniquePersistenObject(184);
  const array84Args = 2;

  function $mapValues$recursiveMapValuesdocument_dataisRef7id5378(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res[
        "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45"
      ][key]
        ? assignOrDefaults(
            acc,
            key,
            getUniquePersistenObject(183),
            array(
              $invalidatedKeys,
              key,
              [
                val,
                $res[
                  "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45"
                ][key]
              ],
              array84Token,
              array84Args,
              true
            ),
            true
          )
        : val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45","*key* : "]
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45","*key* : "]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$recursiveMapValuesdocument_dataisRef7id53");
  function $mapValues$recursiveMapValuesdocument_dataisRef7id53Build() {
    const acc = $res;
    const key = "$mapValues$recursiveMapValuesdocument_dataisRef7id53";
    const prevValue = $res.$mapValues$recursiveMapValuesdocument_dataisRef7id53;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$recursiveMapValuesdocument_dataisRef7id53 = forObject(
      acc,
      key,
      $mapValues$recursiveMapValuesdocument_dataisRef7id5378,
      $res["$recursiveMapValuesdocument_dataisRef7"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$recursiveMapValuesdocument_dataisRef7id53 ||
      (typeof $res.$mapValues$recursiveMapValuesdocument_dataisRef7id53 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$recursiveMapValuesdocument_dataisRef7id53
        ));
    $invalidatedRoots.delete(
      "$mapValues$recursiveMapValuesdocument_dataisRef7id53"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$recursiveMapValuesdocument_dataisRef7","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$recursiveMapValuesdocument_dataisRef7"
    );
    // tracking ["*topLevel* : ","$recursiveMapValuesdocument_dataisRef7","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$recursiveMapValuesdocument_dataisRef7id53;
  }

  const array94Token = getUniquePersistenObject(194);
  const array94Args = 2;

  function $mapValues$recursiveMapValuestheme_dataisRef5id5288(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res[
        "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44"
      ][key]
        ? assignOrDefaults(
            acc,
            key,
            getUniquePersistenObject(193),
            array(
              $invalidatedKeys,
              key,
              [
                val,
                $res[
                  "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44"
                ][key]
              ],
              array94Token,
              array94Args,
              true
            ),
            true
          )
        : val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44","*key* : "]
      //invalidatedPath: ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res[
          "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44"
        ],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44","*key* : "]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$recursiveMapValuestheme_dataisRef5id52");
  function $mapValues$recursiveMapValuestheme_dataisRef5id52Build() {
    const acc = $res;
    const key = "$mapValues$recursiveMapValuestheme_dataisRef5id52";
    const prevValue = $res.$mapValues$recursiveMapValuestheme_dataisRef5id52;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$recursiveMapValuestheme_dataisRef5id52 = forObject(
      acc,
      key,
      $mapValues$recursiveMapValuestheme_dataisRef5id5288,
      $res["$recursiveMapValuestheme_dataisRef5"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$recursiveMapValuestheme_dataisRef5id52 ||
      (typeof $res.$mapValues$recursiveMapValuestheme_dataisRef5id52 ===
        "object" &&
        $tainted.has($res.$mapValues$recursiveMapValuestheme_dataisRef5id52));
    $invalidatedRoots.delete(
      "$mapValues$recursiveMapValuestheme_dataisRef5id52"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$recursiveMapValuestheme_dataisRef5","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$recursiveMapValuestheme_dataisRef5");
    // tracking ["*topLevel* : ","$recursiveMapValuestheme_dataisRef5","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$recursiveMapValuestheme_dataisRef5id52;
  }

  $invalidatedRoots.add(
    "$assign$values$mapValues$mapValues$assignstructure17modes36type434751"
  );
  function $assign$values$mapValues$mapValues$assignstructure17modes36type434751Build() {
    const acc = $res;
    const key =
      "$assign$values$mapValues$mapValues$assignstructure17modes36type434751";
    const prevValue =
      $res.$assign$values$mapValues$mapValues$assignstructure17modes36type434751;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$assign$values$mapValues$mapValues$assignstructure17modes36type434751 = assignOrDefaults(
      acc,
      key,
      getUniquePersistenObject(198),
      $res["$values$mapValues$mapValues$assignstructure17modes36type4347"],
      true
    );
    const $changed =
      prevValue !==
        $res.$assign$values$mapValues$mapValues$assignstructure17modes36type434751 ||
      (typeof $res.$assign$values$mapValues$mapValues$assignstructure17modes36type434751 ===
        "object" &&
        $tainted.has(
          $res.$assign$values$mapValues$mapValues$assignstructure17modes36type434751
        ));
    $invalidatedRoots.delete(
      "$assign$values$mapValues$mapValues$assignstructure17modes36type434751"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$values$mapValues$mapValues$assignstructure17modes36type4347","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$values$mapValues$mapValues$assignstructure17modes36type4347"
    );
    // tracking ["*topLevel* : ","$values$mapValues$mapValues$assignstructure17modes36type4347","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$assign$values$mapValues$mapValues$assignstructure17modes36type434751;
  }

  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50$200(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res["runtime"]["data"]["component_properties"][val["id"]];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","runtime","data","component_properties",["*get* : $id:203, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50$200\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["runtime"]["data"]["component_properties"],
        val["id"]
      );
      // tracking ["*topLevel* : ","runtime","data","component_properties",["*get* : $id:203, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50$200\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50"
  );
  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50Build() {
    const acc = $res;
    const key =
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50";
    const prevValue =
      $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50 = forObject(
      acc,
      key,
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50$200,
      $res[
        "$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41"
      ],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50 ||
      (typeof $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50
        ));
    $invalidatedRoots.delete(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41"
    );
    // tracking ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50;
  }

  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49$208(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res["runtime"]["data"]["connections_data"][val["id"]];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","runtime","data","connections_data",["*get* : $id:211, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49$208\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["runtime"]["data"]["connections_data"],
        val["id"]
      );
      // tracking ["*topLevel* : ","runtime","data","connections_data",["*get* : $id:211, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49$208\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49"
  );
  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49Build() {
    const acc = $res;
    const key =
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49";
    const prevValue =
      $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49 = forObject(
      acc,
      key,
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49$208,
      $res[
        "$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42"
      ],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49 ||
      (typeof $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49
        ));
    $invalidatedRoots.delete(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42"
    );
    // tracking ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49;
  }

  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48$216(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res["runtime"]["data"]["behaviors_data"][val["id"]];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","runtime","data","behaviors_data",["*get* : $id:219, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48$216\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["runtime"]["data"]["behaviors_data"],
        val["id"]
      );
      // tracking ["*topLevel* : ","runtime","data","behaviors_data",["*get* : $id:219, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48$216\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48"
  );
  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48Build() {
    const acc = $res;
    const key =
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48";
    const prevValue =
      $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48 = forObject(
      acc,
      key,
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48$216,
      $res[
        "$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40"
      ],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48 ||
      (typeof $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48
        ));
    $invalidatedRoots.delete(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40"
    );
    // tracking ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48;
  }

  $invalidatedRoots.add(
    "$values$mapValues$mapValues$assignstructure17modes36type4347"
  );
  function $values$mapValues$mapValues$assignstructure17modes36type4347Build() {
    const acc = $res;
    const key = "$values$mapValues$mapValues$assignstructure17modes36type4347";
    const prevValue =
      $res.$values$mapValues$mapValues$assignstructure17modes36type4347;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$values$mapValues$mapValues$assignstructure17modes36type4347 = valuesOrKeysForObject(
      acc,
      key,
      getUniquePersistenObject(224),
      $res["$mapValues$mapValues$assignstructure17modes36type43"],
      true
    );
    const $changed =
      prevValue !==
        $res.$values$mapValues$mapValues$assignstructure17modes36type4347 ||
      (typeof $res.$values$mapValues$mapValues$assignstructure17modes36type4347 ===
        "object" &&
        $tainted.has(
          $res.$values$mapValues$mapValues$assignstructure17modes36type4347
        ));
    $invalidatedRoots.delete(
      "$values$mapValues$mapValues$assignstructure17modes36type4347"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$mapValues$assignstructure17modes36type43","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$mapValues$assignstructure17modes36type43"
    );
    // tracking ["*topLevel* : ","$mapValues$mapValues$assignstructure17modes36type43","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$values$mapValues$mapValues$assignstructure17modes36type4347;
  }

  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46$226(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res["runtime"]["data"]["design_data"][val["id"]];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","runtime","data","design_data",["*get* : $id:229, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46$226\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["runtime"]["data"]["design_data"],
        val["id"]
      );
      // tracking ["*topLevel* : ","runtime","data","design_data",["*get* : $id:229, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46$226\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46"
  );
  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46Build() {
    const acc = $res;
    const key =
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46";
    const prevValue =
      $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46 = forObject(
      acc,
      key,
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46$226,
      $res[
        "$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39"
      ],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46 ||
      (typeof $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46
        ));
    $invalidatedRoots.delete(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39"
    );
    // tracking ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46;
  }

  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45$234(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res["runtime"]["data"]["document_data"][val["id"]];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","runtime","data","document_data",["*get* : $id:237, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45$234\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["runtime"]["data"]["document_data"],
        val["id"]
      );
      // tracking ["*topLevel* : ","runtime","data","document_data",["*get* : $id:237, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45$234\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45"
  );
  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45Build() {
    const acc = $res;
    const key =
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45";
    const prevValue =
      $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45 = forObject(
      acc,
      key,
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45$234,
      $res[
        "$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38"
      ],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45 ||
      (typeof $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45
        ));
    $invalidatedRoots.delete(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38"
    );
    // tracking ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45;
  }

  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44$242(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res["runtime"]["data"]["theme_data"][val["id"]];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","runtime","data","theme_data",["*get* : $id:245, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44$242\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["runtime"]["data"]["theme_data"],
        val["id"]
      );
      // tracking ["*topLevel* : ","runtime","data","theme_data",["*get* : $id:245, $funcId:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44$242\", $rootName:\"$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44\", $depth:1, $funcType:\"mapValues\", $conditional:false","id","*val* : "]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44"
  );
  function $mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44Build() {
    const acc = $res;
    const key =
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44";
    const prevValue =
      $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44 = forObject(
      acc,
      key,
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44$242,
      $res[
        "$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37"
      ],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44 ||
      (typeof $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44
        ));
    $invalidatedRoots.delete(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37"
    );
    // tracking ["*topLevel* : ","$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44;
  }

  function $mapValues$mapValues$assignstructure17modes36type43$250$253(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["type"] === "DEFAULT";
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  function $mapValues$mapValues$assignstructure17modes36type43$250(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        val &&
        forObject(
          acc,
          key,
          $mapValues$mapValues$assignstructure17modes36type43$250$253,
          val,
          null
        );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$mapValues$assignstructure17modes36type43");
  function $mapValues$mapValues$assignstructure17modes36type43Build() {
    const acc = $res;
    const key = "$mapValues$mapValues$assignstructure17modes36type43";
    const prevValue = $res.$mapValues$mapValues$assignstructure17modes36type43;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$mapValues$assignstructure17modes36type43 = forObject(
      acc,
      key,
      $mapValues$mapValues$assignstructure17modes36type43$250,
      $res["$mapValues$assignstructure17modes36"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$mapValues$assignstructure17modes36type43 ||
      (typeof $res.$mapValues$mapValues$assignstructure17modes36type43 ===
        "object" &&
        $tainted.has($res.$mapValues$mapValues$assignstructure17modes36type43));
    $invalidatedRoots.delete(
      "$mapValues$mapValues$assignstructure17modes36type43"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$assignstructure17modes36","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$mapValues$assignstructure17modes36");
    // tracking ["*topLevel* : ","$mapValues$assignstructure17modes36","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$mapValues$assignstructure17modes36type43;
  }

  function $keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42$258(
    $invalidatedKeys,
    $idxToKey,
    src,
    key,
    acc,
    context
  ) {
    let res = null;
    if (key < src.length) {
      const val = src[key];
      res = "" + val["dataId"];
      const $changed =
        acc[res] !== val ||
        (typeof val === "object" && $tainted.has(val)) ||
        (!acc.hasOwnProperty(res) && val === undefined);
      acc[res] = val;
      $idxToKey[key] = res;

      if ($changed) {
        triggerInvalidations(acc, res);
      }
    }
    return res;
  }

  $invalidatedRoots.add(
    "$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42"
  );
  function $keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42Build() {
    const acc = $res;
    const key =
      "$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42";
    const prevValue =
      $res.$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42 = keyByArray(
      acc,
      key,
      $keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42$258,
      $res[
        "$values$mapValues$filterBy$assignstructure17connectionQuery23id3035"
      ],
      null
    );
    const $changed =
      prevValue !==
        $res.$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42 ||
      (typeof $res.$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42 ===
        "object" &&
        $tainted.has(
          $res.$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42
        ));
    $invalidatedRoots.delete(
      "$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17connectionQuery23id3035","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$values$mapValues$filterBy$assignstructure17connectionQuery23id3035"
    );
    // tracking ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17connectionQuery23id3035","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42;
  }

  function $keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41$262(
    $invalidatedKeys,
    $idxToKey,
    src,
    key,
    acc,
    context
  ) {
    let res = null;
    if (key < src.length) {
      const val = src[key];
      res = "" + val["dataId"];
      const $changed =
        acc[res] !== val ||
        (typeof val === "object" && $tainted.has(val)) ||
        (!acc.hasOwnProperty(res) && val === undefined);
      acc[res] = val;
      $idxToKey[key] = res;

      if ($changed) {
        triggerInvalidations(acc, res);
      }
    }
    return res;
  }

  $invalidatedRoots.add(
    "$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41"
  );
  function $keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41Build() {
    const acc = $res;
    const key =
      "$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41";
    const prevValue =
      $res.$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41 = keyByArray(
      acc,
      key,
      $keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41$262,
      $res["$values$mapValues$filterBy$assignstructure17propertyQuery22id2834"],
      null
    );
    const $changed =
      prevValue !==
        $res.$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41 ||
      (typeof $res.$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41 ===
        "object" &&
        $tainted.has(
          $res.$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41
        ));
    $invalidatedRoots.delete(
      "$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17propertyQuery22id2834","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$values$mapValues$filterBy$assignstructure17propertyQuery22id2834"
    );
    // tracking ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17propertyQuery22id2834","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41;
  }

  function $keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40$266(
    $invalidatedKeys,
    $idxToKey,
    src,
    key,
    acc,
    context
  ) {
    let res = null;
    if (key < src.length) {
      const val = src[key];
      res = "" + val["dataId"];
      const $changed =
        acc[res] !== val ||
        (typeof val === "object" && $tainted.has(val)) ||
        (!acc.hasOwnProperty(res) && val === undefined);
      acc[res] = val;
      $idxToKey[key] = res;

      if ($changed) {
        triggerInvalidations(acc, res);
      }
    }
    return res;
  }

  $invalidatedRoots.add(
    "$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40"
  );
  function $keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40Build() {
    const acc = $res;
    const key =
      "$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40";
    const prevValue =
      $res.$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40 = keyByArray(
      acc,
      key,
      $keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40$266,
      $res["$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733"],
      null
    );
    const $changed =
      prevValue !==
        $res.$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40 ||
      (typeof $res.$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40 ===
        "object" &&
        $tainted.has(
          $res.$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40
        ));
    $invalidatedRoots.delete(
      "$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733"
    );
    // tracking ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40;
  }

  function $keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39$270(
    $invalidatedKeys,
    $idxToKey,
    src,
    key,
    acc,
    context
  ) {
    let res = null;
    if (key < src.length) {
      const val = src[key];
      res = "" + val["dataId"];
      const $changed =
        acc[res] !== val ||
        (typeof val === "object" && $tainted.has(val)) ||
        (!acc.hasOwnProperty(res) && val === undefined);
      acc[res] = val;
      $idxToKey[key] = res;

      if ($changed) {
        triggerInvalidations(acc, res);
      }
    }
    return res;
  }

  $invalidatedRoots.add(
    "$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39"
  );
  function $keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39Build() {
    const acc = $res;
    const key =
      "$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39";
    const prevValue =
      $res.$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39 = keyByArray(
      acc,
      key,
      $keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39$270,
      $res["$values$mapValues$filterBy$assignstructure17designQuery20id2632"],
      null
    );
    const $changed =
      prevValue !==
        $res.$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39 ||
      (typeof $res.$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39 ===
        "object" &&
        $tainted.has(
          $res.$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39
        ));
    $invalidatedRoots.delete(
      "$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17designQuery20id2632","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$values$mapValues$filterBy$assignstructure17designQuery20id2632"
    );
    // tracking ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17designQuery20id2632","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39;
  }

  function $keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38$274(
    $invalidatedKeys,
    $idxToKey,
    src,
    key,
    acc,
    context
  ) {
    let res = null;
    if (key < src.length) {
      const val = src[key];
      res = "" + val["dataId"];
      const $changed =
        acc[res] !== val ||
        (typeof val === "object" && $tainted.has(val)) ||
        (!acc.hasOwnProperty(res) && val === undefined);
      acc[res] = val;
      $idxToKey[key] = res;

      if ($changed) {
        triggerInvalidations(acc, res);
      }
    }
    return res;
  }

  $invalidatedRoots.add(
    "$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38"
  );
  function $keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38Build() {
    const acc = $res;
    const key =
      "$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38";
    const prevValue =
      $res.$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38 = keyByArray(
      acc,
      key,
      $keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38$274,
      $res["$values$mapValues$filterBy$assignstructure17dataQuery19id2531"],
      null
    );
    const $changed =
      prevValue !==
        $res.$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38 ||
      (typeof $res.$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38 ===
        "object" &&
        $tainted.has(
          $res.$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38
        ));
    $invalidatedRoots.delete(
      "$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17dataQuery19id2531","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$values$mapValues$filterBy$assignstructure17dataQuery19id2531"
    );
    // tracking ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17dataQuery19id2531","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38;
  }

  function $keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37$278(
    $invalidatedKeys,
    $idxToKey,
    src,
    key,
    acc,
    context
  ) {
    let res = null;
    if (key < src.length) {
      const val = src[key];
      res = "" + val["dataId"];
      const $changed =
        acc[res] !== val ||
        (typeof val === "object" && $tainted.has(val)) ||
        (!acc.hasOwnProperty(res) && val === undefined);
      acc[res] = val;
      $idxToKey[key] = res;

      if ($changed) {
        triggerInvalidations(acc, res);
      }
    }
    return res;
  }

  $invalidatedRoots.add(
    "$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37"
  );
  function $keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37Build() {
    const acc = $res;
    const key =
      "$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37";
    const prevValue =
      $res.$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37 = keyByArray(
      acc,
      key,
      $keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37$278,
      $res["$values$mapValues$filterBy$assignstructure17styleId18id2429"],
      null
    );
    const $changed =
      prevValue !==
        $res.$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37 ||
      (typeof $res.$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37 ===
        "object" &&
        $tainted.has(
          $res.$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37
        ));
    $invalidatedRoots.delete(
      "$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17styleId18id2429","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$values$mapValues$filterBy$assignstructure17styleId18id2429"
    );
    // tracking ["*topLevel* : ","$values$mapValues$filterBy$assignstructure17styleId18id2429","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37;
  }

  function $mapValues$assignstructure17modes36$282$289(
    $invalidatedKeys,
    $idxToKey,
    src,
    key,
    acc,
    context
  ) {
    let res = null;
    if (key < src.length) {
      const val = src[key];
      res = "" + val["modeId"];
      const $changed =
        acc[res] !== val ||
        (typeof val === "object" && $tainted.has(val)) ||
        (!acc.hasOwnProperty(res) && val === undefined);
      acc[res] = val;
      $idxToKey[key] = res;

      if ($changed) {
        triggerInvalidations(acc, res);
      }
    }
    return res;
  }

  function $mapValues$assignstructure17modes36$282(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        (val["modes"] &&
          val["modes"]["definitions"] &&
          keyByArray(
            acc,
            key,
            $mapValues$assignstructure17modes36$282$289,
            val["modes"]["definitions"],
            null
          )) ||
        false;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$assignstructure17modes36");
  function $mapValues$assignstructure17modes36Build() {
    const acc = $res;
    const key = "$mapValues$assignstructure17modes36";
    const prevValue = $res.$mapValues$assignstructure17modes36;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$assignstructure17modes36 = forObject(
      acc,
      key,
      $mapValues$assignstructure17modes36$282,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$assignstructure17modes36 ||
      (typeof $res.$mapValues$assignstructure17modes36 === "object" &&
        $tainted.has($res.$mapValues$assignstructure17modes36));
    $invalidatedRoots.delete("$mapValues$assignstructure17modes36");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$assignstructure17modes36;
  }

  $invalidatedRoots.add(
    "$values$mapValues$filterBy$assignstructure17connectionQuery23id3035"
  );
  function $values$mapValues$filterBy$assignstructure17connectionQuery23id3035Build() {
    const acc = $res;
    const key =
      "$values$mapValues$filterBy$assignstructure17connectionQuery23id3035";
    const prevValue =
      $res.$values$mapValues$filterBy$assignstructure17connectionQuery23id3035;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$values$mapValues$filterBy$assignstructure17connectionQuery23id3035 = valuesOrKeysForObject(
      acc,
      key,
      getUniquePersistenObject(295),
      $res["$mapValues$filterBy$assignstructure17connectionQuery23id30"],
      true
    );
    const $changed =
      prevValue !==
        $res.$values$mapValues$filterBy$assignstructure17connectionQuery23id3035 ||
      (typeof $res.$values$mapValues$filterBy$assignstructure17connectionQuery23id3035 ===
        "object" &&
        $tainted.has(
          $res.$values$mapValues$filterBy$assignstructure17connectionQuery23id3035
        ));
    $invalidatedRoots.delete(
      "$values$mapValues$filterBy$assignstructure17connectionQuery23id3035"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBy$assignstructure17connectionQuery23id30","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$filterBy$assignstructure17connectionQuery23id30"
    );
    // tracking ["*topLevel* : ","$mapValues$filterBy$assignstructure17connectionQuery23id30","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$values$mapValues$filterBy$assignstructure17connectionQuery23id3035;
  }

  $invalidatedRoots.add(
    "$values$mapValues$filterBy$assignstructure17propertyQuery22id2834"
  );
  function $values$mapValues$filterBy$assignstructure17propertyQuery22id2834Build() {
    const acc = $res;
    const key =
      "$values$mapValues$filterBy$assignstructure17propertyQuery22id2834";
    const prevValue =
      $res.$values$mapValues$filterBy$assignstructure17propertyQuery22id2834;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$values$mapValues$filterBy$assignstructure17propertyQuery22id2834 = valuesOrKeysForObject(
      acc,
      key,
      getUniquePersistenObject(297),
      $res["$mapValues$filterBy$assignstructure17propertyQuery22id28"],
      true
    );
    const $changed =
      prevValue !==
        $res.$values$mapValues$filterBy$assignstructure17propertyQuery22id2834 ||
      (typeof $res.$values$mapValues$filterBy$assignstructure17propertyQuery22id2834 ===
        "object" &&
        $tainted.has(
          $res.$values$mapValues$filterBy$assignstructure17propertyQuery22id2834
        ));
    $invalidatedRoots.delete(
      "$values$mapValues$filterBy$assignstructure17propertyQuery22id2834"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBy$assignstructure17propertyQuery22id28","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$filterBy$assignstructure17propertyQuery22id28"
    );
    // tracking ["*topLevel* : ","$mapValues$filterBy$assignstructure17propertyQuery22id28","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$values$mapValues$filterBy$assignstructure17propertyQuery22id2834;
  }

  $invalidatedRoots.add(
    "$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733"
  );
  function $values$mapValues$filterBy$assignstructure17behaviorQuery21id2733Build() {
    const acc = $res;
    const key =
      "$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733";
    const prevValue =
      $res.$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733 = valuesOrKeysForObject(
      acc,
      key,
      getUniquePersistenObject(299),
      $res["$mapValues$filterBy$assignstructure17behaviorQuery21id27"],
      true
    );
    const $changed =
      prevValue !==
        $res.$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733 ||
      (typeof $res.$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733 ===
        "object" &&
        $tainted.has(
          $res.$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733
        ));
    $invalidatedRoots.delete(
      "$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBy$assignstructure17behaviorQuery21id27","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$filterBy$assignstructure17behaviorQuery21id27"
    );
    // tracking ["*topLevel* : ","$mapValues$filterBy$assignstructure17behaviorQuery21id27","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733;
  }

  $invalidatedRoots.add(
    "$values$mapValues$filterBy$assignstructure17designQuery20id2632"
  );
  function $values$mapValues$filterBy$assignstructure17designQuery20id2632Build() {
    const acc = $res;
    const key =
      "$values$mapValues$filterBy$assignstructure17designQuery20id2632";
    const prevValue =
      $res.$values$mapValues$filterBy$assignstructure17designQuery20id2632;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$values$mapValues$filterBy$assignstructure17designQuery20id2632 = valuesOrKeysForObject(
      acc,
      key,
      getUniquePersistenObject(301),
      $res["$mapValues$filterBy$assignstructure17designQuery20id26"],
      true
    );
    const $changed =
      prevValue !==
        $res.$values$mapValues$filterBy$assignstructure17designQuery20id2632 ||
      (typeof $res.$values$mapValues$filterBy$assignstructure17designQuery20id2632 ===
        "object" &&
        $tainted.has(
          $res.$values$mapValues$filterBy$assignstructure17designQuery20id2632
        ));
    $invalidatedRoots.delete(
      "$values$mapValues$filterBy$assignstructure17designQuery20id2632"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBy$assignstructure17designQuery20id26","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$filterBy$assignstructure17designQuery20id26"
    );
    // tracking ["*topLevel* : ","$mapValues$filterBy$assignstructure17designQuery20id26","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$values$mapValues$filterBy$assignstructure17designQuery20id2632;
  }

  $invalidatedRoots.add(
    "$values$mapValues$filterBy$assignstructure17dataQuery19id2531"
  );
  function $values$mapValues$filterBy$assignstructure17dataQuery19id2531Build() {
    const acc = $res;
    const key = "$values$mapValues$filterBy$assignstructure17dataQuery19id2531";
    const prevValue =
      $res.$values$mapValues$filterBy$assignstructure17dataQuery19id2531;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$values$mapValues$filterBy$assignstructure17dataQuery19id2531 = valuesOrKeysForObject(
      acc,
      key,
      getUniquePersistenObject(303),
      $res["$mapValues$filterBy$assignstructure17dataQuery19id25"],
      true
    );
    const $changed =
      prevValue !==
        $res.$values$mapValues$filterBy$assignstructure17dataQuery19id2531 ||
      (typeof $res.$values$mapValues$filterBy$assignstructure17dataQuery19id2531 ===
        "object" &&
        $tainted.has(
          $res.$values$mapValues$filterBy$assignstructure17dataQuery19id2531
        ));
    $invalidatedRoots.delete(
      "$values$mapValues$filterBy$assignstructure17dataQuery19id2531"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBy$assignstructure17dataQuery19id25","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$filterBy$assignstructure17dataQuery19id25"
    );
    // tracking ["*topLevel* : ","$mapValues$filterBy$assignstructure17dataQuery19id25","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$values$mapValues$filterBy$assignstructure17dataQuery19id2531;
  }

  const object$307Token = getUniquePersistenObject(307);
  const object$307Args = ["id", "dataId"];

  function $mapValues$filterBy$assignstructure17connectionQuery23id30$305(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        { id: key, dataId: $funcLib["removeHash"](val["connectionQuery"]) },
        object$307Token,
        object$307Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$filterBy$assignstructure17connectionQuery23id30"
  );
  function $mapValues$filterBy$assignstructure17connectionQuery23id30Build() {
    const acc = $res;
    const key = "$mapValues$filterBy$assignstructure17connectionQuery23id30";
    const prevValue =
      $res.$mapValues$filterBy$assignstructure17connectionQuery23id30;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBy$assignstructure17connectionQuery23id30 = forObject(
      acc,
      key,
      $mapValues$filterBy$assignstructure17connectionQuery23id30$305,
      $res["$filterBy$assignstructure17connectionQuery23"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$filterBy$assignstructure17connectionQuery23id30 ||
      (typeof $res.$mapValues$filterBy$assignstructure17connectionQuery23id30 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$filterBy$assignstructure17connectionQuery23id30
        ));
    $invalidatedRoots.delete(
      "$mapValues$filterBy$assignstructure17connectionQuery23id30"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBy$assignstructure17connectionQuery23","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$filterBy$assignstructure17connectionQuery23"
    );
    // tracking ["*topLevel* : ","$filterBy$assignstructure17connectionQuery23","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBy$assignstructure17connectionQuery23id30;
  }

  $invalidatedRoots.add(
    "$values$mapValues$filterBy$assignstructure17styleId18id2429"
  );
  function $values$mapValues$filterBy$assignstructure17styleId18id2429Build() {
    const acc = $res;
    const key = "$values$mapValues$filterBy$assignstructure17styleId18id2429";
    const prevValue =
      $res.$values$mapValues$filterBy$assignstructure17styleId18id2429;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$values$mapValues$filterBy$assignstructure17styleId18id2429 = valuesOrKeysForObject(
      acc,
      key,
      getUniquePersistenObject(311),
      $res["$mapValues$filterBy$assignstructure17styleId18id24"],
      true
    );
    const $changed =
      prevValue !==
        $res.$values$mapValues$filterBy$assignstructure17styleId18id2429 ||
      (typeof $res.$values$mapValues$filterBy$assignstructure17styleId18id2429 ===
        "object" &&
        $tainted.has(
          $res.$values$mapValues$filterBy$assignstructure17styleId18id2429
        ));
    $invalidatedRoots.delete(
      "$values$mapValues$filterBy$assignstructure17styleId18id2429"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBy$assignstructure17styleId18id24","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$filterBy$assignstructure17styleId18id24"
    );
    // tracking ["*topLevel* : ","$mapValues$filterBy$assignstructure17styleId18id24","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$values$mapValues$filterBy$assignstructure17styleId18id2429;
  }

  const object$315Token = getUniquePersistenObject(315);
  const object$315Args = ["id", "dataId"];

  function $mapValues$filterBy$assignstructure17propertyQuery22id28$313(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        { id: key, dataId: $funcLib["removeHash"](val["propertyQuery"]) },
        object$315Token,
        object$315Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$filterBy$assignstructure17propertyQuery22id28"
  );
  function $mapValues$filterBy$assignstructure17propertyQuery22id28Build() {
    const acc = $res;
    const key = "$mapValues$filterBy$assignstructure17propertyQuery22id28";
    const prevValue =
      $res.$mapValues$filterBy$assignstructure17propertyQuery22id28;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBy$assignstructure17propertyQuery22id28 = forObject(
      acc,
      key,
      $mapValues$filterBy$assignstructure17propertyQuery22id28$313,
      $res["$filterBy$assignstructure17propertyQuery22"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$filterBy$assignstructure17propertyQuery22id28 ||
      (typeof $res.$mapValues$filterBy$assignstructure17propertyQuery22id28 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$filterBy$assignstructure17propertyQuery22id28
        ));
    $invalidatedRoots.delete(
      "$mapValues$filterBy$assignstructure17propertyQuery22id28"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBy$assignstructure17propertyQuery22","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$filterBy$assignstructure17propertyQuery22"
    );
    // tracking ["*topLevel* : ","$filterBy$assignstructure17propertyQuery22","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBy$assignstructure17propertyQuery22id28;
  }

  const object$321Token = getUniquePersistenObject(321);
  const object$321Args = ["id", "dataId"];

  function $mapValues$filterBy$assignstructure17behaviorQuery21id27$319(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        { id: key, dataId: $funcLib["removeHash"](val["behaviorQuery"]) },
        object$321Token,
        object$321Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$filterBy$assignstructure17behaviorQuery21id27"
  );
  function $mapValues$filterBy$assignstructure17behaviorQuery21id27Build() {
    const acc = $res;
    const key = "$mapValues$filterBy$assignstructure17behaviorQuery21id27";
    const prevValue =
      $res.$mapValues$filterBy$assignstructure17behaviorQuery21id27;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBy$assignstructure17behaviorQuery21id27 = forObject(
      acc,
      key,
      $mapValues$filterBy$assignstructure17behaviorQuery21id27$319,
      $res["$filterBy$assignstructure17behaviorQuery21"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$filterBy$assignstructure17behaviorQuery21id27 ||
      (typeof $res.$mapValues$filterBy$assignstructure17behaviorQuery21id27 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$filterBy$assignstructure17behaviorQuery21id27
        ));
    $invalidatedRoots.delete(
      "$mapValues$filterBy$assignstructure17behaviorQuery21id27"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBy$assignstructure17behaviorQuery21","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$filterBy$assignstructure17behaviorQuery21"
    );
    // tracking ["*topLevel* : ","$filterBy$assignstructure17behaviorQuery21","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBy$assignstructure17behaviorQuery21id27;
  }

  const object$327Token = getUniquePersistenObject(327);
  const object$327Args = ["id", "dataId"];

  function $mapValues$filterBy$assignstructure17designQuery20id26$325(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        { id: key, dataId: $funcLib["removeHash"](val["designQuery"]) },
        object$327Token,
        object$327Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$filterBy$assignstructure17designQuery20id26"
  );
  function $mapValues$filterBy$assignstructure17designQuery20id26Build() {
    const acc = $res;
    const key = "$mapValues$filterBy$assignstructure17designQuery20id26";
    const prevValue =
      $res.$mapValues$filterBy$assignstructure17designQuery20id26;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBy$assignstructure17designQuery20id26 = forObject(
      acc,
      key,
      $mapValues$filterBy$assignstructure17designQuery20id26$325,
      $res["$filterBy$assignstructure17designQuery20"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$filterBy$assignstructure17designQuery20id26 ||
      (typeof $res.$mapValues$filterBy$assignstructure17designQuery20id26 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$filterBy$assignstructure17designQuery20id26
        ));
    $invalidatedRoots.delete(
      "$mapValues$filterBy$assignstructure17designQuery20id26"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBy$assignstructure17designQuery20","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$filterBy$assignstructure17designQuery20"
    );
    // tracking ["*topLevel* : ","$filterBy$assignstructure17designQuery20","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBy$assignstructure17designQuery20id26;
  }

  const object$333Token = getUniquePersistenObject(333);
  const object$333Args = ["id", "dataId"];

  function $mapValues$filterBy$assignstructure17dataQuery19id25$331(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        { id: key, dataId: $funcLib["removeHash"](val["dataQuery"]) },
        object$333Token,
        object$333Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$filterBy$assignstructure17dataQuery19id25");
  function $mapValues$filterBy$assignstructure17dataQuery19id25Build() {
    const acc = $res;
    const key = "$mapValues$filterBy$assignstructure17dataQuery19id25";
    const prevValue = $res.$mapValues$filterBy$assignstructure17dataQuery19id25;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBy$assignstructure17dataQuery19id25 = forObject(
      acc,
      key,
      $mapValues$filterBy$assignstructure17dataQuery19id25$331,
      $res["$filterBy$assignstructure17dataQuery19"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$filterBy$assignstructure17dataQuery19id25 ||
      (typeof $res.$mapValues$filterBy$assignstructure17dataQuery19id25 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$filterBy$assignstructure17dataQuery19id25
        ));
    $invalidatedRoots.delete(
      "$mapValues$filterBy$assignstructure17dataQuery19id25"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBy$assignstructure17dataQuery19","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$filterBy$assignstructure17dataQuery19"
    );
    // tracking ["*topLevel* : ","$filterBy$assignstructure17dataQuery19","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBy$assignstructure17dataQuery19id25;
  }

  const object$339Token = getUniquePersistenObject(339);
  const object$339Args = ["id", "dataId"];

  function $mapValues$filterBy$assignstructure17styleId18id24$337(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        { id: key, dataId: $funcLib["removeHash"](val["styleId"]) },
        object$339Token,
        object$339Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$filterBy$assignstructure17styleId18id24");
  function $mapValues$filterBy$assignstructure17styleId18id24Build() {
    const acc = $res;
    const key = "$mapValues$filterBy$assignstructure17styleId18id24";
    const prevValue = $res.$mapValues$filterBy$assignstructure17styleId18id24;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBy$assignstructure17styleId18id24 = forObject(
      acc,
      key,
      $mapValues$filterBy$assignstructure17styleId18id24$337,
      $res["$filterBy$assignstructure17styleId18"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$filterBy$assignstructure17styleId18id24 ||
      (typeof $res.$mapValues$filterBy$assignstructure17styleId18id24 ===
        "object" &&
        $tainted.has($res.$mapValues$filterBy$assignstructure17styleId18id24));
    $invalidatedRoots.delete(
      "$mapValues$filterBy$assignstructure17styleId18id24"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBy$assignstructure17styleId18","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$filterBy$assignstructure17styleId18");
    // tracking ["*topLevel* : ","$filterBy$assignstructure17styleId18","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBy$assignstructure17styleId18id24;
  }

  function $filterBy$assignstructure17connectionQuery23$343(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["connectionQuery"];
      if (res) {
        $changed =
          acc[key] !== val ||
          (typeof val === "object" && $tainted.has(val)) ||
          (!acc.hasOwnProperty(key) && val === undefined);
        acc[key] = val;
      } else if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$filterBy$assignstructure17connectionQuery23");
  function $filterBy$assignstructure17connectionQuery23Build() {
    const acc = $res;
    const key = "$filterBy$assignstructure17connectionQuery23";
    const prevValue = $res.$filterBy$assignstructure17connectionQuery23;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$filterBy$assignstructure17connectionQuery23 = forObject(
      acc,
      key,
      $filterBy$assignstructure17connectionQuery23$343,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.$filterBy$assignstructure17connectionQuery23 ||
      (typeof $res.$filterBy$assignstructure17connectionQuery23 === "object" &&
        $tainted.has($res.$filterBy$assignstructure17connectionQuery23));
    $invalidatedRoots.delete("$filterBy$assignstructure17connectionQuery23");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$filterBy$assignstructure17connectionQuery23;
  }

  function $filterBy$assignstructure17propertyQuery22$347(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["propertyQuery"];
      if (res) {
        $changed =
          acc[key] !== val ||
          (typeof val === "object" && $tainted.has(val)) ||
          (!acc.hasOwnProperty(key) && val === undefined);
        acc[key] = val;
      } else if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$filterBy$assignstructure17propertyQuery22");
  function $filterBy$assignstructure17propertyQuery22Build() {
    const acc = $res;
    const key = "$filterBy$assignstructure17propertyQuery22";
    const prevValue = $res.$filterBy$assignstructure17propertyQuery22;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$filterBy$assignstructure17propertyQuery22 = forObject(
      acc,
      key,
      $filterBy$assignstructure17propertyQuery22$347,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.$filterBy$assignstructure17propertyQuery22 ||
      (typeof $res.$filterBy$assignstructure17propertyQuery22 === "object" &&
        $tainted.has($res.$filterBy$assignstructure17propertyQuery22));
    $invalidatedRoots.delete("$filterBy$assignstructure17propertyQuery22");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$filterBy$assignstructure17propertyQuery22;
  }

  function $filterBy$assignstructure17behaviorQuery21$351(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["behaviorQuery"];
      if (res) {
        $changed =
          acc[key] !== val ||
          (typeof val === "object" && $tainted.has(val)) ||
          (!acc.hasOwnProperty(key) && val === undefined);
        acc[key] = val;
      } else if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$filterBy$assignstructure17behaviorQuery21");
  function $filterBy$assignstructure17behaviorQuery21Build() {
    const acc = $res;
    const key = "$filterBy$assignstructure17behaviorQuery21";
    const prevValue = $res.$filterBy$assignstructure17behaviorQuery21;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$filterBy$assignstructure17behaviorQuery21 = forObject(
      acc,
      key,
      $filterBy$assignstructure17behaviorQuery21$351,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.$filterBy$assignstructure17behaviorQuery21 ||
      (typeof $res.$filterBy$assignstructure17behaviorQuery21 === "object" &&
        $tainted.has($res.$filterBy$assignstructure17behaviorQuery21));
    $invalidatedRoots.delete("$filterBy$assignstructure17behaviorQuery21");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$filterBy$assignstructure17behaviorQuery21;
  }

  function $filterBy$assignstructure17designQuery20$355(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["designQuery"];
      if (res) {
        $changed =
          acc[key] !== val ||
          (typeof val === "object" && $tainted.has(val)) ||
          (!acc.hasOwnProperty(key) && val === undefined);
        acc[key] = val;
      } else if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$filterBy$assignstructure17designQuery20");
  function $filterBy$assignstructure17designQuery20Build() {
    const acc = $res;
    const key = "$filterBy$assignstructure17designQuery20";
    const prevValue = $res.$filterBy$assignstructure17designQuery20;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$filterBy$assignstructure17designQuery20 = forObject(
      acc,
      key,
      $filterBy$assignstructure17designQuery20$355,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.$filterBy$assignstructure17designQuery20 ||
      (typeof $res.$filterBy$assignstructure17designQuery20 === "object" &&
        $tainted.has($res.$filterBy$assignstructure17designQuery20));
    $invalidatedRoots.delete("$filterBy$assignstructure17designQuery20");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$filterBy$assignstructure17designQuery20;
  }

  function $filterBy$assignstructure17dataQuery19$359(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["dataQuery"];
      if (res) {
        $changed =
          acc[key] !== val ||
          (typeof val === "object" && $tainted.has(val)) ||
          (!acc.hasOwnProperty(key) && val === undefined);
        acc[key] = val;
      } else if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$filterBy$assignstructure17dataQuery19");
  function $filterBy$assignstructure17dataQuery19Build() {
    const acc = $res;
    const key = "$filterBy$assignstructure17dataQuery19";
    const prevValue = $res.$filterBy$assignstructure17dataQuery19;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$filterBy$assignstructure17dataQuery19 = forObject(
      acc,
      key,
      $filterBy$assignstructure17dataQuery19$359,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.$filterBy$assignstructure17dataQuery19 ||
      (typeof $res.$filterBy$assignstructure17dataQuery19 === "object" &&
        $tainted.has($res.$filterBy$assignstructure17dataQuery19));
    $invalidatedRoots.delete("$filterBy$assignstructure17dataQuery19");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$filterBy$assignstructure17dataQuery19;
  }

  function $filterBy$assignstructure17styleId18$363(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["styleId"];
      if (res) {
        $changed =
          acc[key] !== val ||
          (typeof val === "object" && $tainted.has(val)) ||
          (!acc.hasOwnProperty(key) && val === undefined);
        acc[key] = val;
      } else if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$filterBy$assignstructure17styleId18");
  function $filterBy$assignstructure17styleId18Build() {
    const acc = $res;
    const key = "$filterBy$assignstructure17styleId18";
    const prevValue = $res.$filterBy$assignstructure17styleId18;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$filterBy$assignstructure17styleId18 = forObject(
      acc,
      key,
      $filterBy$assignstructure17styleId18$363,
      $res["$assignstructure17"],
      null
    );
    const $changed =
      prevValue !== $res.$filterBy$assignstructure17styleId18 ||
      (typeof $res.$filterBy$assignstructure17styleId18 === "object" &&
        $tainted.has($res.$filterBy$assignstructure17styleId18));
    $invalidatedRoots.delete("$filterBy$assignstructure17styleId18");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$assignstructure17","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$assignstructure17");
    // tracking ["*topLevel* : ","$assignstructure17","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$filterBy$assignstructure17styleId18;
  }

  const array$368Token = getUniquePersistenObject(368);
  const array$368Args = 3;

  $invalidatedRoots.add("$assignstructure17");
  function $assignstructure17Build() {
    const acc = $res;
    const key = "$assignstructure17";
    const prevValue = $res.$assignstructure17;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$assignstructure17 = assignOrDefaults(
      acc,
      key,
      getUniquePersistenObject(367),
      array(
        $invalidatedKeys,
        key,
        [
          $model["structure"],
          $res["$mapValues$filterBystructuretype0id12"],
          $res[
            "$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516"
          ]
        ],
        array$368Token,
        array$368Args,
        true
      ),
      true
    );
    const $changed =
      prevValue !== $res.$assignstructure17 ||
      (typeof $res.$assignstructure17 === "object" &&
        $tainted.has($res.$assignstructure17));
    $invalidatedRoots.delete("$assignstructure17");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*root* : ","structure"], false, undefined
    // path matched ["*root* : ","structure","*arg0* : "]
    track($invalidatedKeys, key, $model, "structure");
    // path matched ["*root* : ","structure","*arg0* : ","*arg1* : "]
    track($invalidatedKeys, key, $model, "structure");
    // path matched ["*root* : ","structure","*arg0* : ","components","*key* : "]
    track($invalidatedKeys, key, $model, "structure");
    // tracking model directly
    // tracking ["*root* : ","structure"]
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBystructuretype0id12"], false, undefined
    track($invalidatedKeys, key, $res, "$mapValues$filterBystructuretype0id12");
    // tracking ["*topLevel* : ","$mapValues$filterBystructuretype0id12"]
    //invalidatedPath: ["*topLevel* : ","$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516"], false, undefined
    track(
      $invalidatedKeys,
      key,
      $res,
      "$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516"
    );
    // tracking ["*topLevel* : ","$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516"]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$assignstructure17;
  }

  $invalidatedRoots.add(
    "$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516"
  );
  function $assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516Build() {
    const acc = $res;
    const key =
      "$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516";
    const prevValue =
      $res.$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516 = assignOrDefaults(
      acc,
      key,
      getUniquePersistenObject(372),
      $res[
        "$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415"
      ],
      true
    );
    const $changed =
      prevValue !==
        $res.$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516 ||
      (typeof $res.$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516 ===
        "object" &&
        $tainted.has(
          $res.$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516
        ));
    $invalidatedRoots.delete(
      "$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415"
    );
    // tracking ["*topLevel* : ","$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516;
  }

  $invalidatedRoots.add(
    "$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415"
  );
  function $values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415Build() {
    const acc = $res;
    const key =
      "$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415";
    const prevValue =
      $res.$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415 = valuesOrKeysForObject(
      acc,
      key,
      getUniquePersistenObject(374),
      $res[
        "$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14"
      ],
      true
    );
    const $changed =
      prevValue !==
        $res.$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415 ||
      (typeof $res.$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415 ===
        "object" &&
        $tainted.has(
          $res.$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415
        ));
    $invalidatedRoots.delete(
      "$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14"
    );
    // tracking ["*topLevel* : ","$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415;
  }

  function $mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14$376$378(
    $invalidatedKeys,
    $idxToKey,
    src,
    key,
    acc,
    context
  ) {
    let res = null;
    if (key < src.length) {
      const val = src[key];
      res = "" + val["id"];
      const $changed =
        acc[res] !== val ||
        (typeof val === "object" && $tainted.has(val)) ||
        (!acc.hasOwnProperty(res) && val === undefined);
      acc[res] = val;
      $idxToKey[key] = res;

      if ($changed) {
        triggerInvalidations(acc, res);
      }
    }
    return res;
  }

  function $mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14$376(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = keyByArray(
        acc,
        key,
        $mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14$376$378,
        val,
        null
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14"
  );
  function $mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14Build() {
    const acc = $res;
    const key =
      "$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14";
    const prevValue =
      $res.$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14 = forObject(
      acc,
      key,
      $mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14$376,
      $res["$mapValues$mapValues$filterBystructureparent4parent11id13"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14 ||
      (typeof $res.$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14
        ));
    $invalidatedRoots.delete(
      "$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$mapValues$filterBystructureparent4parent11id13","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$mapValues$filterBystructureparent4parent11id13"
    );
    // tracking ["*topLevel* : ","$mapValues$mapValues$filterBystructureparent4parent11id13","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14;
  }

  function $mapValues$mapValues$filterBystructureparent4parent11id13$382$384$419(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res = val + "_" + context;
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  const object$386Token = getUniquePersistenObject(386);
  const object$386Args = [
    "id",
    "type",
    "parent",
    "componentType",
    "skin",
    "dataQuery",
    "connectionQuery",
    "propertyQuery",
    "layout",
    "designQuery",
    "behaviorQuery",
    "styleId",
    "modes",
    "components"
  ];

  function $mapValues$mapValues$filterBystructureparent4parent11id13$382$384(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        {
          id: context["id"] + "_" + val,
          type: context["type"],
          parent: $res["$filterBystructuretype0"][context["parent"]]
            ? context["parent"]
            : context["parent"] + "_" + val,
          componentType: context["componentType"],
          skin: context["skin"],
          dataQuery: context["dataQuery"]
            ? context["dataQuery"] + "_" + val
            : "",
          connectionQuery: context["connectionQuery"],
          propertyQuery: context["propertyQuery"],
          layout: context["layout"],
          designQuery: context["designQuery"]
            ? context["designQuery"] + "_" + val
            : "",
          behaviorQuery: context["behaviorQuery"],
          styleId: context["styleId"],
          modes: context["modes"],
          components: context["components"]
            ? forArray(
                acc,
                key,
                $mapValues$mapValues$filterBystructureparent4parent11id13$382$384$419,
                context["components"],
                val
              )
            : false
        },
        object$386Token,
        object$386Args,
        true
      );
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$filterBystructuretype0",["*get* : $id:393, $funcId:\"$mapValues$mapValues$filterBystructureparent4parent11id13$382$384\", $rootName:\"$mapValues$mapValues$filterBystructureparent4parent11id13\", $depth:2, $funcType:\"map\", $conditional:false","parent","*context* : "]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["$filterBystructuretype0"],
        context["parent"]
      );
      // tracking ["*topLevel* : ","$filterBystructuretype0",["*get* : $id:393, $funcId:\"$mapValues$mapValues$filterBystructureparent4parent11id13$382$384\", $rootName:\"$mapValues$mapValues$filterBystructureparent4parent11id13\", $depth:2, $funcType:\"map\", $conditional:false","parent","*context* : "]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  function $mapValues$mapValues$filterBystructureparent4parent11id13$382(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = forArray(
        acc,
        key,
        $mapValues$mapValues$filterBystructureparent4parent11id13$382$384,
        val,
        $res["$filterBystructureparent4"][key]
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$mapValues$filterBystructureparent4parent11id13"
  );
  function $mapValues$mapValues$filterBystructureparent4parent11id13Build() {
    const acc = $res;
    const key = "$mapValues$mapValues$filterBystructureparent4parent11id13";
    const prevValue =
      $res.$mapValues$mapValues$filterBystructureparent4parent11id13;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$mapValues$filterBystructureparent4parent11id13 = forObject(
      acc,
      key,
      $mapValues$mapValues$filterBystructureparent4parent11id13$382,
      $res["$mapValues$filterBystructureparent4parent11"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$mapValues$filterBystructureparent4parent11id13 ||
      (typeof $res.$mapValues$mapValues$filterBystructureparent4parent11id13 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$mapValues$filterBystructureparent4parent11id13
        ));
    $invalidatedRoots.delete(
      "$mapValues$mapValues$filterBystructureparent4parent11id13"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBystructureparent4parent11","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$filterBystructureparent4parent11"
    );
    // tracking ["*topLevel* : ","$mapValues$filterBystructureparent4parent11","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$mapValues$filterBystructureparent4parent11id13;
  }

  function $mapValues$filterBystructuretype0id12$427$443(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res = context + "_" + val;
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  const object$429Token = getUniquePersistenObject(429);
  const object$429Args = [
    "id",
    "type",
    "parent",
    "componentType",
    "skin",
    "dataQuery",
    "connectionQuery",
    "propertyQuery",
    "layout",
    "designQuery",
    "behaviorQuery",
    "styleId",
    "modes",
    "components"
  ];

  function $mapValues$filterBystructuretype0id12$427(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = object(
        $invalidatedKeys,
        key,
        {
          id: val["id"],
          type: val["type"],
          parent: val["parent"],
          componentType: val["componentType"],
          skin: val["skin"],
          dataQuery: val["dataQuery"],
          connectionQuery: val["connectionQuery"],
          propertyQuery: val["propertyQuery"],
          layout: val["layout"],
          designQuery: val["designQuery"],
          behaviorQuery: val["behaviorQuery"],
          styleId: val["styleId"],
          modes: val["modes"],
          components: forArray(
            acc,
            key,
            $mapValues$filterBystructuretype0id12$427$443,
            $res[
              "$mapValues$mapValues$filterBystructuretype0removeHash1items2"
            ][key],
            val["components"][0]
          )
        },
        object$429Token,
        object$429Args,
        true
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$mapValues$mapValues$filterBystructuretype0removeHash1items2","*key* : ","*wildcard* : "], false, wildcard
      track(
        $invalidatedKeys,
        key,
        $res["$mapValues$mapValues$filterBystructuretype0removeHash1items2"],
        key
      );
      // tracking ["*topLevel* : ","$mapValues$mapValues$filterBystructuretype0removeHash1items2","*key* : ","*wildcard* : "]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$filterBystructuretype0id12");
  function $mapValues$filterBystructuretype0id12Build() {
    const acc = $res;
    const key = "$mapValues$filterBystructuretype0id12";
    const prevValue = $res.$mapValues$filterBystructuretype0id12;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBystructuretype0id12 = forObject(
      acc,
      key,
      $mapValues$filterBystructuretype0id12$427,
      $res["$filterBystructuretype0"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$filterBystructuretype0id12 ||
      (typeof $res.$mapValues$filterBystructuretype0id12 === "object" &&
        $tainted.has($res.$mapValues$filterBystructuretype0id12));
    $invalidatedRoots.delete("$mapValues$filterBystructuretype0id12");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBystructuretype0","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$filterBystructuretype0");
    // tracking ["*topLevel* : ","$filterBystructuretype0","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBystructuretype0id12;
  }

  function $mapValues$filterBystructureparent4parent11$452(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        $res["$mapValues$mapValues$filterBystructuretype0removeHash1items2"][
          $res["$recursiveMapValuesstructureparent3"][key]
        ];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$mapValues$mapValues$filterBystructuretype0removeHash1items2",["*get* : $id:455, $funcId:\"$mapValues$filterBystructureparent4parent11$452\", $rootName:\"$mapValues$filterBystructureparent4parent11\", $depth:1, $funcType:\"mapValues\", $conditional:false","*key* : ",["*get* : $id:456, $funcId:\"$mapValues$filterBystructureparent4parent11$452\", $rootName:\"$mapValues$filterBystructureparent4parent11\", $depth:1, $funcType:\"mapValues\", $conditional:false","$recursiveMapValuesstructureparent3","*topLevel* : "]]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["$mapValues$mapValues$filterBystructuretype0removeHash1items2"],
        $res["$recursiveMapValuesstructureparent3"][key]
      );
      // tracking ["*topLevel* : ","$mapValues$mapValues$filterBystructuretype0removeHash1items2",["*get* : $id:455, $funcId:\"$mapValues$filterBystructureparent4parent11$452\", $rootName:\"$mapValues$filterBystructureparent4parent11\", $depth:1, $funcType:\"mapValues\", $conditional:false","*key* : ",["*get* : $id:456, $funcId:\"$mapValues$filterBystructureparent4parent11$452\", $rootName:\"$mapValues$filterBystructureparent4parent11\", $depth:1, $funcType:\"mapValues\", $conditional:false","$recursiveMapValuesstructureparent3","*topLevel* : "]]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$filterBystructureparent4parent11");
  function $mapValues$filterBystructureparent4parent11Build() {
    const acc = $res;
    const key = "$mapValues$filterBystructureparent4parent11";
    const prevValue = $res.$mapValues$filterBystructureparent4parent11;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBystructureparent4parent11 = forObject(
      acc,
      key,
      $mapValues$filterBystructureparent4parent11$452,
      $res["$filterBystructureparent4"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$filterBystructureparent4parent11 ||
      (typeof $res.$mapValues$filterBystructureparent4parent11 === "object" &&
        $tainted.has($res.$mapValues$filterBystructureparent4parent11));
    $invalidatedRoots.delete("$mapValues$filterBystructureparent4parent11");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBystructureparent4","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$filterBystructureparent4");
    // tracking ["*topLevel* : ","$filterBystructureparent4","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBystructureparent4parent11;
  }

  function $recursiveMapValuescomponent_propertiesisRef10$459$461$473(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res =
        context.recursiveSteps(
          $funcLib["removeHash"](val),
          $invalidatedKeys,
          key
        ) || val;
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  function $recursiveMapValuescomponent_propertiesisRef10$459$461(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        ($funcLib["isRef"](val, key, context["itemType"]) &&
          context["loop"].recursiveSteps(
            $funcLib["removeHash"](val),
            $invalidatedKeys,
            key
          )) ||
        ($funcLib["isRefList"](val, key, context["itemType"]) &&
          forArray(
            acc,
            key,
            $recursiveMapValuescomponent_propertiesisRef10$459$461$473,
            val,
            context["loop"]
          )) ||
        val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  const object$479Token = getUniquePersistenObject(479);
  const object$479Args = ["loop", "itemType"];

  function $recursiveMapValuescomponent_propertiesisRef10$459(
    $invalidatedKeys,
    src,
    key,
    acc,
    context,
    loop
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = forObject(
        acc,
        key,
        $recursiveMapValuescomponent_propertiesisRef10$459$461,
        val,
        object(
          $invalidatedKeys,
          key,
          { loop: loop, itemType: val["type"] },
          object$479Token,
          object$479Args,
          true
        )
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$recursiveMapValuescomponent_propertiesisRef10");
  function $recursiveMapValuescomponent_propertiesisRef10Build() {
    const acc = $res;
    const key = "$recursiveMapValuescomponent_propertiesisRef10";
    const prevValue = $res.$recursiveMapValuescomponent_propertiesisRef10;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$recursiveMapValuescomponent_propertiesisRef10 = recursiveMapObject(
      acc,
      key,
      $recursiveMapValuescomponent_propertiesisRef10$459,
      $res["data"]["component_properties"],
      null
    );
    const $changed =
      prevValue !== $res.$recursiveMapValuescomponent_propertiesisRef10 ||
      (typeof $res.$recursiveMapValuescomponent_propertiesisRef10 ===
        "object" &&
        $tainted.has($res.$recursiveMapValuescomponent_propertiesisRef10));
    $invalidatedRoots.delete("$recursiveMapValuescomponent_propertiesisRef10");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","data","component_properties","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res["data"], "component_properties");
    // tracking ["*topLevel* : ","data","component_properties","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$recursiveMapValuescomponent_propertiesisRef10;
  }

  function $recursiveMapValuesconnections_dataisRef9$483$485$497(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res =
        context.recursiveSteps(
          $funcLib["removeHash"](val),
          $invalidatedKeys,
          key
        ) || val;
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  function $recursiveMapValuesconnections_dataisRef9$483$485(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        ($funcLib["isRef"](val, key, context["itemType"]) &&
          context["loop"].recursiveSteps(
            $funcLib["removeHash"](val),
            $invalidatedKeys,
            key
          )) ||
        ($funcLib["isRefList"](val, key, context["itemType"]) &&
          forArray(
            acc,
            key,
            $recursiveMapValuesconnections_dataisRef9$483$485$497,
            val,
            context["loop"]
          )) ||
        val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  const object$503Token = getUniquePersistenObject(503);
  const object$503Args = ["loop", "itemType"];

  function $recursiveMapValuesconnections_dataisRef9$483(
    $invalidatedKeys,
    src,
    key,
    acc,
    context,
    loop
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = forObject(
        acc,
        key,
        $recursiveMapValuesconnections_dataisRef9$483$485,
        val,
        object(
          $invalidatedKeys,
          key,
          { loop: loop, itemType: val["type"] },
          object$503Token,
          object$503Args,
          true
        )
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$recursiveMapValuesconnections_dataisRef9");
  function $recursiveMapValuesconnections_dataisRef9Build() {
    const acc = $res;
    const key = "$recursiveMapValuesconnections_dataisRef9";
    const prevValue = $res.$recursiveMapValuesconnections_dataisRef9;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$recursiveMapValuesconnections_dataisRef9 = recursiveMapObject(
      acc,
      key,
      $recursiveMapValuesconnections_dataisRef9$483,
      $res["data"]["connections_data"],
      null
    );
    const $changed =
      prevValue !== $res.$recursiveMapValuesconnections_dataisRef9 ||
      (typeof $res.$recursiveMapValuesconnections_dataisRef9 === "object" &&
        $tainted.has($res.$recursiveMapValuesconnections_dataisRef9));
    $invalidatedRoots.delete("$recursiveMapValuesconnections_dataisRef9");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","data","connections_data","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res["data"], "connections_data");
    // tracking ["*topLevel* : ","data","connections_data","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$recursiveMapValuesconnections_dataisRef9;
  }

  function $recursiveMapValuesbehaviors_dataisRef8$507$509$521(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res =
        context.recursiveSteps(
          $funcLib["removeHash"](val),
          $invalidatedKeys,
          key
        ) || val;
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  function $recursiveMapValuesbehaviors_dataisRef8$507$509(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        ($funcLib["isRef"](val, key, context["itemType"]) &&
          context["loop"].recursiveSteps(
            $funcLib["removeHash"](val),
            $invalidatedKeys,
            key
          )) ||
        ($funcLib["isRefList"](val, key, context["itemType"]) &&
          forArray(
            acc,
            key,
            $recursiveMapValuesbehaviors_dataisRef8$507$509$521,
            val,
            context["loop"]
          )) ||
        val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  const object$527Token = getUniquePersistenObject(527);
  const object$527Args = ["loop", "itemType"];

  function $recursiveMapValuesbehaviors_dataisRef8$507(
    $invalidatedKeys,
    src,
    key,
    acc,
    context,
    loop
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = forObject(
        acc,
        key,
        $recursiveMapValuesbehaviors_dataisRef8$507$509,
        val,
        object(
          $invalidatedKeys,
          key,
          { loop: loop, itemType: val["type"] },
          object$527Token,
          object$527Args,
          true
        )
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$recursiveMapValuesbehaviors_dataisRef8");
  function $recursiveMapValuesbehaviors_dataisRef8Build() {
    const acc = $res;
    const key = "$recursiveMapValuesbehaviors_dataisRef8";
    const prevValue = $res.$recursiveMapValuesbehaviors_dataisRef8;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$recursiveMapValuesbehaviors_dataisRef8 = recursiveMapObject(
      acc,
      key,
      $recursiveMapValuesbehaviors_dataisRef8$507,
      $res["data"]["behaviors_data"],
      null
    );
    const $changed =
      prevValue !== $res.$recursiveMapValuesbehaviors_dataisRef8 ||
      (typeof $res.$recursiveMapValuesbehaviors_dataisRef8 === "object" &&
        $tainted.has($res.$recursiveMapValuesbehaviors_dataisRef8));
    $invalidatedRoots.delete("$recursiveMapValuesbehaviors_dataisRef8");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","data","behaviors_data","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res["data"], "behaviors_data");
    // tracking ["*topLevel* : ","data","behaviors_data","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$recursiveMapValuesbehaviors_dataisRef8;
  }

  function $recursiveMapValuesdocument_dataisRef7$531$533$545(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res =
        context.recursiveSteps(
          $funcLib["removeHash"](val),
          $invalidatedKeys,
          key
        ) || val;
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  function $recursiveMapValuesdocument_dataisRef7$531$533(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        ($funcLib["isRef"](val, key, context["itemType"]) &&
          context["loop"].recursiveSteps(
            $funcLib["removeHash"](val),
            $invalidatedKeys,
            key
          )) ||
        ($funcLib["isRefList"](val, key, context["itemType"]) &&
          forArray(
            acc,
            key,
            $recursiveMapValuesdocument_dataisRef7$531$533$545,
            val,
            context["loop"]
          )) ||
        val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  const object$551Token = getUniquePersistenObject(551);
  const object$551Args = ["loop", "itemType"];

  function $recursiveMapValuesdocument_dataisRef7$531(
    $invalidatedKeys,
    src,
    key,
    acc,
    context,
    loop
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = forObject(
        acc,
        key,
        $recursiveMapValuesdocument_dataisRef7$531$533,
        val,
        object(
          $invalidatedKeys,
          key,
          { loop: loop, itemType: val["type"] },
          object$551Token,
          object$551Args,
          true
        )
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$recursiveMapValuesdocument_dataisRef7");
  function $recursiveMapValuesdocument_dataisRef7Build() {
    const acc = $res;
    const key = "$recursiveMapValuesdocument_dataisRef7";
    const prevValue = $res.$recursiveMapValuesdocument_dataisRef7;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$recursiveMapValuesdocument_dataisRef7 = recursiveMapObject(
      acc,
      key,
      $recursiveMapValuesdocument_dataisRef7$531,
      $res["data"]["document_data"],
      null
    );
    const $changed =
      prevValue !== $res.$recursiveMapValuesdocument_dataisRef7 ||
      (typeof $res.$recursiveMapValuesdocument_dataisRef7 === "object" &&
        $tainted.has($res.$recursiveMapValuesdocument_dataisRef7));
    $invalidatedRoots.delete("$recursiveMapValuesdocument_dataisRef7");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","data","document_data","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res["data"], "document_data");
    // tracking ["*topLevel* : ","data","document_data","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$recursiveMapValuesdocument_dataisRef7;
  }

  function $recursiveMapValuesdesign_dataisRef6$555$557$569(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res =
        context.recursiveSteps(
          $funcLib["removeHash"](val),
          $invalidatedKeys,
          key
        ) || val;
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  function $recursiveMapValuesdesign_dataisRef6$555$557(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        ($funcLib["isRef"](val, key, context["itemType"]) &&
          context["loop"].recursiveSteps(
            $funcLib["removeHash"](val),
            $invalidatedKeys,
            key
          )) ||
        ($funcLib["isRefList"](val, key, context["itemType"]) &&
          forArray(
            acc,
            key,
            $recursiveMapValuesdesign_dataisRef6$555$557$569,
            val,
            context["loop"]
          )) ||
        val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  const object$575Token = getUniquePersistenObject(575);
  const object$575Args = ["loop", "itemType"];

  function $recursiveMapValuesdesign_dataisRef6$555(
    $invalidatedKeys,
    src,
    key,
    acc,
    context,
    loop
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = forObject(
        acc,
        key,
        $recursiveMapValuesdesign_dataisRef6$555$557,
        val,
        object(
          $invalidatedKeys,
          key,
          { loop: loop, itemType: val["type"] },
          object$575Token,
          object$575Args,
          true
        )
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$recursiveMapValuesdesign_dataisRef6");
  function $recursiveMapValuesdesign_dataisRef6Build() {
    const acc = $res;
    const key = "$recursiveMapValuesdesign_dataisRef6";
    const prevValue = $res.$recursiveMapValuesdesign_dataisRef6;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$recursiveMapValuesdesign_dataisRef6 = recursiveMapObject(
      acc,
      key,
      $recursiveMapValuesdesign_dataisRef6$555,
      $res["data"]["design_data"],
      null
    );
    const $changed =
      prevValue !== $res.$recursiveMapValuesdesign_dataisRef6 ||
      (typeof $res.$recursiveMapValuesdesign_dataisRef6 === "object" &&
        $tainted.has($res.$recursiveMapValuesdesign_dataisRef6));
    $invalidatedRoots.delete("$recursiveMapValuesdesign_dataisRef6");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","data","design_data","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res["data"], "design_data");
    // tracking ["*topLevel* : ","data","design_data","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$recursiveMapValuesdesign_dataisRef6;
  }

  function $recursiveMapValuestheme_dataisRef5$579$581$593(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (key >= src.length) {
      $changed = true;
      if (acc.length >= key) {
        acc.length = src.length;
      }
    } else {
      const res =
        context.recursiveSteps(
          $funcLib["removeHash"](val),
          $invalidatedKeys,
          key
        ) || val;
      $changed =
        res !== acc[key] || (typeof res === "object" && $tainted.has(res));
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  function $recursiveMapValuestheme_dataisRef5$579$581(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        ($funcLib["isRef"](val, key, context["itemType"]) &&
          context["loop"].recursiveSteps(
            $funcLib["removeHash"](val),
            $invalidatedKeys,
            key
          )) ||
        ($funcLib["isRefList"](val, key, context["itemType"]) &&
          forArray(
            acc,
            key,
            $recursiveMapValuestheme_dataisRef5$579$581$593,
            val,
            context["loop"]
          )) ||
        val;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  const object$599Token = getUniquePersistenObject(599);
  const object$599Args = ["loop", "itemType"];

  function $recursiveMapValuestheme_dataisRef5$579(
    $invalidatedKeys,
    src,
    key,
    acc,
    context,
    loop
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = forObject(
        acc,
        key,
        $recursiveMapValuestheme_dataisRef5$579$581,
        val,
        object(
          $invalidatedKeys,
          key,
          { loop: loop, itemType: val["type"] },
          object$599Token,
          object$599Args,
          true
        )
      );
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$recursiveMapValuestheme_dataisRef5");
  function $recursiveMapValuestheme_dataisRef5Build() {
    const acc = $res;
    const key = "$recursiveMapValuestheme_dataisRef5";
    const prevValue = $res.$recursiveMapValuestheme_dataisRef5;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$recursiveMapValuestheme_dataisRef5 = recursiveMapObject(
      acc,
      key,
      $recursiveMapValuestheme_dataisRef5$579,
      $res["data"]["theme_data"],
      null
    );
    const $changed =
      prevValue !== $res.$recursiveMapValuestheme_dataisRef5 ||
      (typeof $res.$recursiveMapValuestheme_dataisRef5 === "object" &&
        $tainted.has($res.$recursiveMapValuestheme_dataisRef5));
    $invalidatedRoots.delete("$recursiveMapValuestheme_dataisRef5");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","data","theme_data","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res["data"], "theme_data");
    // tracking ["*topLevel* : ","data","theme_data","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$recursiveMapValuestheme_dataisRef5;
  }

  function $filterBystructureparent4$603(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = $res["$recursiveMapValuesstructureparent3"][key];
      if (res) {
        $changed =
          acc[key] !== val ||
          (typeof val === "object" && $tainted.has(val)) ||
          (!acc.hasOwnProperty(key) && val === undefined);
        acc[key] = val;
      } else if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","$recursiveMapValuesstructureparent3","*key* : "], false, key
      track(
        $invalidatedKeys,
        key,
        $res["$recursiveMapValuesstructureparent3"],
        key
      );
      // tracking ["*topLevel* : ","$recursiveMapValuesstructureparent3","*key* : "]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$filterBystructureparent4");
  function $filterBystructureparent4Build() {
    const acc = $res;
    const key = "$filterBystructureparent4";
    const prevValue = $res.$filterBystructureparent4;
    $res.$filterBystructureparent4 = forObject(
      acc,
      key,
      $filterBystructureparent4$603,
      $model["structure"],
      null
    );
    const $changed =
      prevValue !== $res.$filterBystructureparent4 ||
      (typeof $res.$filterBystructureparent4 === "object" &&
        $tainted.has($res.$filterBystructureparent4));
    $invalidatedRoots.delete("$filterBystructureparent4");
    //invalidatedPath: ["*root* : ","structure","*wildcard* : "], false, wildcard
    // path matched ["*root* : ","structure","*arg0* : "]
    // path matched ["*root* : ","structure","*arg0* : ","*arg1* : "]
    // path matched ["*root* : ","structure","*arg0* : ","components","*key* : "]
    // tracking model directly
    // tracking ["*root* : ","structure","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$filterBystructureparent4;
  }

  function $recursiveMapValuesstructureparent3$608(
    $invalidatedKeys,
    src,
    key,
    acc,
    context,
    loop
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["parent"]
        ? $model["structure"][val["parent"]]["type"] === "RepeaterContainer"
          ? val["parent"]
          : loop.recursiveSteps(val["parent"], $invalidatedKeys, key)
        : false;
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*root* : ","structure",["*get* : $id:616, $funcId:\"$recursiveMapValuesstructureparent3$608\", $rootName:\"$recursiveMapValuesstructureparent3\", $depth:1, $funcType:\"recursiveMapValues\", $conditional:false","parent","*val* : "],"type"], false, undefined
      // path matched ["*root* : ","structure","*arg0* : "]
      track($invalidatedKeys, key, $model["structure"], val["parent"]);
      // tracking model directly
      // tracking ["*root* : ","structure",["*get* : $id:616, $funcId:\"$recursiveMapValuesstructureparent3$608\", $rootName:\"$recursiveMapValuesstructureparent3\", $depth:1, $funcType:\"recursiveMapValues\", $conditional:false","parent","*val* : "],"type"]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$recursiveMapValuesstructureparent3");
  function $recursiveMapValuesstructureparent3Build() {
    const acc = $res;
    const key = "$recursiveMapValuesstructureparent3";
    const prevValue = $res.$recursiveMapValuesstructureparent3;
    $res.$recursiveMapValuesstructureparent3 = recursiveMapObject(
      acc,
      key,
      $recursiveMapValuesstructureparent3$608,
      $model["structure"],
      null
    );
    const $changed =
      prevValue !== $res.$recursiveMapValuesstructureparent3 ||
      (typeof $res.$recursiveMapValuesstructureparent3 === "object" &&
        $tainted.has($res.$recursiveMapValuesstructureparent3));
    $invalidatedRoots.delete("$recursiveMapValuesstructureparent3");
    //invalidatedPath: ["*root* : ","structure","*wildcard* : "], false, wildcard
    // path matched ["*root* : ","structure","*arg0* : "]
    // path matched ["*root* : ","structure","*arg0* : ","*arg1* : "]
    // path matched ["*root* : ","structure","*arg0* : ","components","*key* : "]
    // tracking model directly
    // tracking ["*root* : ","structure","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$recursiveMapValuesstructureparent3;
  }

  function $mapValues$mapValues$filterBystructuretype0removeHash1items2$622(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["items"];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add(
    "$mapValues$mapValues$filterBystructuretype0removeHash1items2"
  );
  function $mapValues$mapValues$filterBystructuretype0removeHash1items2Build() {
    const acc = $res;
    const key = "$mapValues$mapValues$filterBystructuretype0removeHash1items2";
    const prevValue =
      $res.$mapValues$mapValues$filterBystructuretype0removeHash1items2;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$mapValues$filterBystructuretype0removeHash1items2 = forObject(
      acc,
      key,
      $mapValues$mapValues$filterBystructuretype0removeHash1items2$622,
      $res["$mapValues$filterBystructuretype0removeHash1"],
      null
    );
    const $changed =
      prevValue !==
        $res.$mapValues$mapValues$filterBystructuretype0removeHash1items2 ||
      (typeof $res.$mapValues$mapValues$filterBystructuretype0removeHash1items2 ===
        "object" &&
        $tainted.has(
          $res.$mapValues$mapValues$filterBystructuretype0removeHash1items2
        ));
    $invalidatedRoots.delete(
      "$mapValues$mapValues$filterBystructuretype0removeHash1items2"
    );
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$mapValues$filterBystructuretype0removeHash1","*wildcard* : "], false, wildcard
    track(
      $invalidatedKeys,
      key,
      $res,
      "$mapValues$filterBystructuretype0removeHash1"
    );
    // tracking ["*topLevel* : ","$mapValues$filterBystructuretype0removeHash1","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$mapValues$filterBystructuretype0removeHash1items2;
  }

  function $mapValues$filterBystructuretype0removeHash1$626(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res =
        $res["data"]["document_data"][$funcLib["removeHash"](val["dataQuery"])];
      $changed =
        res !== acc[key] ||
        (typeof res === "object" && $tainted.has(res)) ||
        (!acc.hasOwnProperty(key) && res === undefined);
      acc[key] = res;
      untrack($invalidatedKeys, key);
      //invalidatedPath: ["*topLevel* : ","data","document_data",["*call* : $id:629, $funcId:\"$mapValues$filterBystructuretype0removeHash1$626\", $rootName:\"$mapValues$filterBystructuretype0removeHash1\", $depth:1, $funcType:\"mapValues\", $conditional:false","removeHash",["*get* : $id:630, $funcId:\"$mapValues$filterBystructuretype0removeHash1$626\", $rootName:\"$mapValues$filterBystructuretype0removeHash1\", $depth:1, $funcType:\"mapValues\", $conditional:false","dataQuery","*val* : "]]], false, undefined
      track(
        $invalidatedKeys,
        key,
        $res["data"]["document_data"],
        $funcLib["removeHash"](val["dataQuery"])
      );
      // tracking ["*topLevel* : ","data","document_data",["*call* : $id:629, $funcId:\"$mapValues$filterBystructuretype0removeHash1$626\", $rootName:\"$mapValues$filterBystructuretype0removeHash1\", $depth:1, $funcType:\"mapValues\", $conditional:false","removeHash",["*get* : $id:630, $funcId:\"$mapValues$filterBystructuretype0removeHash1$626\", $rootName:\"$mapValues$filterBystructuretype0removeHash1\", $depth:1, $funcType:\"mapValues\", $conditional:false","dataQuery","*val* : "]]]
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$mapValues$filterBystructuretype0removeHash1");
  function $mapValues$filterBystructuretype0removeHash1Build() {
    const acc = $res;
    const key = "$mapValues$filterBystructuretype0removeHash1";
    const prevValue = $res.$mapValues$filterBystructuretype0removeHash1;
    const $invalidatedKeys = $invalidatedRoots;
    $res.$mapValues$filterBystructuretype0removeHash1 = forObject(
      acc,
      key,
      $mapValues$filterBystructuretype0removeHash1$626,
      $res["$filterBystructuretype0"],
      null
    );
    const $changed =
      prevValue !== $res.$mapValues$filterBystructuretype0removeHash1 ||
      (typeof $res.$mapValues$filterBystructuretype0removeHash1 === "object" &&
        $tainted.has($res.$mapValues$filterBystructuretype0removeHash1));
    $invalidatedRoots.delete("$mapValues$filterBystructuretype0removeHash1");
    untrack($invalidatedKeys, key);
    //invalidatedPath: ["*topLevel* : ","$filterBystructuretype0","*wildcard* : "], false, wildcard
    track($invalidatedKeys, key, $res, "$filterBystructuretype0");
    // tracking ["*topLevel* : ","$filterBystructuretype0","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$mapValues$filterBystructuretype0removeHash1;
  }

  function $filterBystructuretype0$634(
    $invalidatedKeys,
    src,
    key,
    acc,
    context
  ) {
    let $changed = false;

    const val = src[key];
    if (!src.hasOwnProperty(key)) {
      if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    } else {
      const res = val["type"] === "RepeaterContainer";
      if (res) {
        $changed =
          acc[key] !== val ||
          (typeof val === "object" && $tainted.has(val)) ||
          (!acc.hasOwnProperty(key) && val === undefined);
        acc[key] = val;
      } else if (acc.hasOwnProperty(key)) {
        delete acc[key];
        $changed = true;
      }
    }

    if ($changed) {
      triggerInvalidations(acc, key);
    }
  }

  $invalidatedRoots.add("$filterBystructuretype0");
  function $filterBystructuretype0Build() {
    const acc = $res;
    const key = "$filterBystructuretype0";
    const prevValue = $res.$filterBystructuretype0;
    $res.$filterBystructuretype0 = forObject(
      acc,
      key,
      $filterBystructuretype0$634,
      $model["structure"],
      null
    );
    const $changed =
      prevValue !== $res.$filterBystructuretype0 ||
      (typeof $res.$filterBystructuretype0 === "object" &&
        $tainted.has($res.$filterBystructuretype0));
    $invalidatedRoots.delete("$filterBystructuretype0");
    //invalidatedPath: ["*root* : ","structure","*wildcard* : "], false, wildcard
    // path matched ["*root* : ","structure","*arg0* : "]
    // path matched ["*root* : ","structure","*arg0* : ","*arg1* : "]
    // path matched ["*root* : ","structure","*arg0* : ","components","*key* : "]
    // tracking model directly
    // tracking ["*root* : ","structure","*wildcard* : "]

    if ($changed) {
      triggerInvalidations(acc, key);
    }

    return $res.$filterBystructuretype0;
  }

  let $inBatch = false;
  function recalculate() {
    if ($inBatch) {
      return;
    }
    $invalidatedRoots.has("data") && $dataBuild();
    $invalidatedRoots.has("runtime") && $runtimeBuild();
    $invalidatedRoots.has("$recursiveMapValuescomponent_propertiesisRef10") &&
      $recursiveMapValuescomponent_propertiesisRef10Build();
    $invalidatedRoots.has("$recursiveMapValuesconnections_dataisRef9") &&
      $recursiveMapValuesconnections_dataisRef9Build();
    $invalidatedRoots.has("$recursiveMapValuesbehaviors_dataisRef8") &&
      $recursiveMapValuesbehaviors_dataisRef8Build();
    $invalidatedRoots.has("$recursiveMapValuesdesign_dataisRef6") &&
      $recursiveMapValuesdesign_dataisRef6Build();
    $invalidatedRoots.has("$recursiveMapValuesdocument_dataisRef7") &&
      $recursiveMapValuesdocument_dataisRef7Build();
    $invalidatedRoots.has("$recursiveMapValuestheme_dataisRef5") &&
      $recursiveMapValuestheme_dataisRef5Build();
    $invalidatedRoots.has("$filterBystructuretype0") &&
      $filterBystructuretype0Build();
    $invalidatedRoots.has("$recursiveMapValuesstructureparent3") &&
      $recursiveMapValuesstructureparent3Build();
    $invalidatedRoots.has("$filterBystructureparent4") &&
      $filterBystructureparent4Build();
    $invalidatedRoots.has("$mapValues$filterBystructuretype0removeHash1") &&
      $mapValues$filterBystructuretype0removeHash1Build();
    $invalidatedRoots.has(
      "$mapValues$mapValues$filterBystructuretype0removeHash1items2"
    ) && $mapValues$mapValues$filterBystructuretype0removeHash1items2Build();
    $invalidatedRoots.has("$mapValues$filterBystructuretype0id12") &&
      $mapValues$filterBystructuretype0id12Build();
    $invalidatedRoots.has("$mapValues$filterBystructureparent4parent11") &&
      $mapValues$filterBystructureparent4parent11Build();
    $invalidatedRoots.has(
      "$mapValues$mapValues$filterBystructureparent4parent11id13"
    ) && $mapValues$mapValues$filterBystructureparent4parent11id13Build();
    $invalidatedRoots.has(
      "$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14"
    ) &&
      $mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id14Build();
    $invalidatedRoots.has(
      "$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415"
    ) &&
      $values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id1415Build();
    $invalidatedRoots.has(
      "$assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516"
    ) &&
      $assign$values$mapValues$mapValues$mapValues$filterBystructureparent4parent11id13id141516Build();
    $invalidatedRoots.has("$assignstructure17") && $assignstructure17Build();
    $invalidatedRoots.has("$mapValues$assignstructure17modes36") &&
      $mapValues$assignstructure17modes36Build();
    $invalidatedRoots.has(
      "$mapValues$mapValues$assignstructure17modes36type43"
    ) && $mapValues$mapValues$assignstructure17modes36type43Build();
    $invalidatedRoots.has(
      "$values$mapValues$mapValues$assignstructure17modes36type4347"
    ) && $values$mapValues$mapValues$assignstructure17modes36type4347Build();
    $invalidatedRoots.has(
      "$assign$values$mapValues$mapValues$assignstructure17modes36type434751"
    ) &&
      $assign$values$mapValues$mapValues$assignstructure17modes36type434751Build();
    $invalidatedRoots.has("activeModes") && $activeModesBuild();
    $invalidatedRoots.has("overridesOfCompInMode") &&
      $overridesOfCompInModeBuild();
    $invalidatedRoots.has("overridesOfCompInModeWithDefaults") &&
      $overridesOfCompInModeWithDefaultsBuild();
    $invalidatedRoots.has("$mapValues$assignstructure17id58") &&
      $mapValues$assignstructure17id58Build();
    $invalidatedRoots.has("structure") && $structureBuild();
    $invalidatedRoots.has("$filterBy$assignstructure17connectionQuery23") &&
      $filterBy$assignstructure17connectionQuery23Build();
    $invalidatedRoots.has(
      "$mapValues$filterBy$assignstructure17connectionQuery23id30"
    ) && $mapValues$filterBy$assignstructure17connectionQuery23id30Build();
    $invalidatedRoots.has(
      "$values$mapValues$filterBy$assignstructure17connectionQuery23id3035"
    ) &&
      $values$mapValues$filterBy$assignstructure17connectionQuery23id3035Build();
    $invalidatedRoots.has(
      "$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42"
    ) &&
      $keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42Build();
    $invalidatedRoots.has(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49"
    ) &&
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17connectionQuery23id3035dataId42id49Build();
    $invalidatedRoots.has(
      "$mapValues$recursiveMapValuesconnections_dataisRef9id56"
    ) && $mapValues$recursiveMapValuesconnections_dataisRef9id56Build();
    $invalidatedRoots.has("connections_data") && $connections_dataBuild();
    $invalidatedRoots.has("$filterBy$assignstructure17propertyQuery22") &&
      $filterBy$assignstructure17propertyQuery22Build();
    $invalidatedRoots.has(
      "$mapValues$filterBy$assignstructure17propertyQuery22id28"
    ) && $mapValues$filterBy$assignstructure17propertyQuery22id28Build();
    $invalidatedRoots.has(
      "$values$mapValues$filterBy$assignstructure17propertyQuery22id2834"
    ) &&
      $values$mapValues$filterBy$assignstructure17propertyQuery22id2834Build();
    $invalidatedRoots.has(
      "$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41"
    ) &&
      $keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41Build();
    $invalidatedRoots.has(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50"
    ) &&
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17propertyQuery22id2834dataId41id50Build();
    $invalidatedRoots.has(
      "$mapValues$recursiveMapValuescomponent_propertiesisRef10id57"
    ) && $mapValues$recursiveMapValuescomponent_propertiesisRef10id57Build();
    $invalidatedRoots.has("component_properties") &&
      $component_propertiesBuild();
    $invalidatedRoots.has("$filterBy$assignstructure17behaviorQuery21") &&
      $filterBy$assignstructure17behaviorQuery21Build();
    $invalidatedRoots.has(
      "$mapValues$filterBy$assignstructure17behaviorQuery21id27"
    ) && $mapValues$filterBy$assignstructure17behaviorQuery21id27Build();
    $invalidatedRoots.has(
      "$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733"
    ) &&
      $values$mapValues$filterBy$assignstructure17behaviorQuery21id2733Build();
    $invalidatedRoots.has(
      "$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40"
    ) &&
      $keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40Build();
    $invalidatedRoots.has(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48"
    ) &&
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17behaviorQuery21id2733dataId40id48Build();
    $invalidatedRoots.has(
      "$mapValues$recursiveMapValuesbehaviors_dataisRef8id55"
    ) && $mapValues$recursiveMapValuesbehaviors_dataisRef8id55Build();
    $invalidatedRoots.has("behaviors_data") && $behaviors_dataBuild();
    $invalidatedRoots.has("$filterBy$assignstructure17designQuery20") &&
      $filterBy$assignstructure17designQuery20Build();
    $invalidatedRoots.has(
      "$mapValues$filterBy$assignstructure17designQuery20id26"
    ) && $mapValues$filterBy$assignstructure17designQuery20id26Build();
    $invalidatedRoots.has(
      "$values$mapValues$filterBy$assignstructure17designQuery20id2632"
    ) && $values$mapValues$filterBy$assignstructure17designQuery20id2632Build();
    $invalidatedRoots.has(
      "$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39"
    ) &&
      $keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39Build();
    $invalidatedRoots.has(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46"
    ) &&
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17designQuery20id2632dataId39id46Build();
    $invalidatedRoots.has(
      "$mapValues$recursiveMapValuesdesign_dataisRef6id54"
    ) && $mapValues$recursiveMapValuesdesign_dataisRef6id54Build();
    $invalidatedRoots.has("design_data") && $design_dataBuild();
    $invalidatedRoots.has("$filterBy$assignstructure17dataQuery19") &&
      $filterBy$assignstructure17dataQuery19Build();
    $invalidatedRoots.has(
      "$mapValues$filterBy$assignstructure17dataQuery19id25"
    ) && $mapValues$filterBy$assignstructure17dataQuery19id25Build();
    $invalidatedRoots.has(
      "$values$mapValues$filterBy$assignstructure17dataQuery19id2531"
    ) && $values$mapValues$filterBy$assignstructure17dataQuery19id2531Build();
    $invalidatedRoots.has(
      "$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38"
    ) &&
      $keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38Build();
    $invalidatedRoots.has(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45"
    ) &&
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17dataQuery19id2531dataId38id45Build();
    $invalidatedRoots.has(
      "$mapValues$recursiveMapValuesdocument_dataisRef7id53"
    ) && $mapValues$recursiveMapValuesdocument_dataisRef7id53Build();
    $invalidatedRoots.has("document_data") && $document_dataBuild();
    $invalidatedRoots.has("$filterBy$assignstructure17styleId18") &&
      $filterBy$assignstructure17styleId18Build();
    $invalidatedRoots.has(
      "$mapValues$filterBy$assignstructure17styleId18id24"
    ) && $mapValues$filterBy$assignstructure17styleId18id24Build();
    $invalidatedRoots.has(
      "$values$mapValues$filterBy$assignstructure17styleId18id2429"
    ) && $values$mapValues$filterBy$assignstructure17styleId18id2429Build();
    $invalidatedRoots.has(
      "$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37"
    ) &&
      $keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37Build();
    $invalidatedRoots.has(
      "$mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44"
    ) &&
      $mapValues$keyBy$values$mapValues$filterBy$assignstructure17styleId18id2429dataId37id44Build();
    $invalidatedRoots.has(
      "$mapValues$recursiveMapValuestheme_dataisRef5id52"
    ) && $mapValues$recursiveMapValuestheme_dataisRef5id52Build();
    $invalidatedRoots.has("theme_data") && $theme_dataBuild();
    $tainted = new WeakSet();
  }
  Object.assign(
    $res,
    {
      updateComponent: (arg0, value) => {
        triggerInvalidations($model["structure"], arg0);
        triggerInvalidations($model, "structure");
        $tainted.add($model["structure"]);
        $tainted.add($model);
        if (typeof value === "undefined") {
          delete $model["structure"][arg0];
        } else {
          $model["structure"][arg0] = value;
        }
        recalculate();
      },
      updateComponentProperty: (arg0, arg1, value) => {
        triggerInvalidations($model["structure"][arg0], arg1);
        triggerInvalidations($model["structure"], arg0);
        triggerInvalidations($model, "structure");
        $tainted.add($model["structure"][arg0]);
        $tainted.add($model["structure"]);
        $tainted.add($model);
        if (typeof value === "undefined") {
          delete $model["structure"][arg0][arg1];
        } else {
          $model["structure"][arg0][arg1] = value;
        }
        recalculate();
      },
      updateDataItem: (arg0, arg1, value) => {
        triggerInvalidations($model["data"][arg0], arg1);
        triggerInvalidations($model["data"], arg0);
        triggerInvalidations($model, "data");
        $tainted.add($model["data"][arg0]);
        $tainted.add($model["data"]);
        $tainted.add($model);
        if (typeof value === "undefined") {
          delete $model["data"][arg0][arg1];
        } else {
          $model["data"][arg0][arg1] = value;
        }
        recalculate();
      },
      updateDataItemField: (arg0, arg1, value) => {
        triggerInvalidations($model["data"]["document_data"][arg0], arg1);
        triggerInvalidations($model["data"]["document_data"], arg0);
        triggerInvalidations($model["data"], "document_data");
        triggerInvalidations($model, "data");
        $tainted.add($model["data"]["document_data"][arg0]);
        $tainted.add($model["data"]["document_data"]);
        $tainted.add($model["data"]);
        $tainted.add($model);
        if (typeof value === "undefined") {
          delete $model["data"]["document_data"][arg0][arg1];
        } else {
          $model["data"]["document_data"][arg0][arg1] = value;
        }
        recalculate();
      },
      updateActiveModes: (arg0, value) => {
        triggerInvalidations($model["activeModes"], arg0);
        triggerInvalidations($model, "activeModes");
        $tainted.add($model["activeModes"]);
        $tainted.add($model);
        if (typeof value === "undefined") {
          delete $model["activeModes"][arg0];
        } else {
          $model["activeModes"][arg0] = value;
        }
        recalculate();
      },
      updateComponentOverrides: (arg0, arg1, value) => {
        triggerInvalidations($model["runtime"]["data"][arg0], arg1);
        triggerInvalidations($model["runtime"]["data"], arg0);
        triggerInvalidations($model["runtime"], "data");
        triggerInvalidations($model, "runtime");
        $tainted.add($model["runtime"]["data"][arg0]);
        $tainted.add($model["runtime"]["data"]);
        $tainted.add($model["runtime"]);
        $tainted.add($model);
        if (typeof value === "undefined") {
          delete $model["runtime"]["data"][arg0][arg1];
        } else {
          $model["runtime"]["data"][arg0][arg1] = value;
        }
        recalculate();
      },
      spliceComponents: (arg0, key, len, ...newItems) => {
        const arr = $model["structure"][arg0]["components"];
        const origLength = arr.length;
        const end =
          len === newItems.length
            ? key + len
            : Math.max(origLength, origLength + newItems.length - len);
        for (let i = key; i < end; i++) {
          triggerInvalidations(arr, i);
        }
        triggerInvalidations($model["structure"][arg0]["components"], key);
        triggerInvalidations($model["structure"][arg0], "components");
        triggerInvalidations($model["structure"], arg0);
        triggerInvalidations($model, "structure");
        $tainted.add($model["structure"][arg0]["components"]);
        $tainted.add($model["structure"][arg0]);
        $tainted.add($model["structure"]);
        $tainted.add($model);
        $model["structure"][arg0]["components"].splice(key, len, ...newItems);
        recalculate();
      }
    },
    {
      $startBatch: () => ($inBatch = true),
      $endBatch: () => {
        $inBatch = false;
        recalculate();
      }
    }
  );
  recalculate();
  return $res;
}

module.exports = model;
